var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticStyle: { "background-color": "#fff" },
    attrs: {
      id: _vm.id,
      src: _vm.url,
      frameborder: "0",
      width: "100%",
      height: "800px",
      scrolling: "auto"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }