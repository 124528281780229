var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "draggable",
        {
          staticStyle: { display: "inline-block" },
          attrs: { options: { animation: 300 } },
          on: { end: _vm.end },
          model: {
            value: _vm.dataSource,
            callback: function($$v) {
              _vm.dataSource = $$v
            },
            expression: "dataSource"
          }
        },
        [
          _vm._l(_vm.dataSource, function(data, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    float: "left",
                    width: "150px",
                    height: "150px",
                    "margin-right": "10px",
                    margin: "0 8px 8px 0"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        padding: "8px",
                        border: "1px solid #d9d9d9",
                        "border-radius": "4px"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: data.filePath, preview: "index" }
                      })
                    ]
                  )
                ]
              )
            ]
          }),
          _c(
            "a-button",
            {
              staticStyle: { "margin-top": "115px" },
              attrs: { type: "primary" },
              on: { click: _vm.sureChange }
            },
            [_vm._v("确定")]
          )
        ],
        2
      ),
      _c("br"),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("p", [_vm._v("拖拽前json数据：")]),
            _c(
              "textarea",
              { staticStyle: { width: "780px" }, attrs: { rows: "25" } },
              [_vm._v(_vm._s(_vm.oldDateSource))]
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("p", [_vm._v("拖拽后json数据：")]),
            _c(
              "textarea",
              { staticStyle: { width: "780px" }, attrs: { rows: "25" } },
              [_vm._v(_vm._s(_vm.newDateSource))]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }