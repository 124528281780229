var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "page-header-index-wide page-header-wrapper-grid-content-main"
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 7 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("div", { staticClass: "account-center-avatarHolder" }, [
                    _c("div", { staticClass: "avatar" }, [
                      _c("img", { attrs: { src: _vm.getAvatar() } })
                    ]),
                    _c("div", { staticClass: "username" }, [
                      _vm._v(_vm._s(_vm.nickname()))
                    ]),
                    _c("div", { staticClass: "bio" }, [
                      _vm._v("海纳百川，有容乃大")
                    ])
                  ]),
                  _c("div", { staticClass: "account-center-detail" }, [
                    _c("p", [
                      _c("i", { staticClass: "title" }),
                      _vm._v("交互专家\n          ")
                    ]),
                    _c("p", [
                      _c("i", { staticClass: "group" }),
                      _vm._v(
                        "蚂蚁金服－某某某事业群－某某平台部－某某技术部－UED\n          "
                      )
                    ]),
                    _c("p", [
                      _c("i", { staticClass: "address" }),
                      _c("span", [_vm._v("浙江省")]),
                      _c("span", [_vm._v("杭州市")])
                    ])
                  ]),
                  _c("a-divider"),
                  _c("div", { staticClass: "account-center-tags" }, [
                    _c("div", { staticClass: "tagsTitle" }, [_vm._v("标签")]),
                    _c(
                      "div",
                      [
                        _vm._l(_vm.tags, function(tag, index) {
                          return [
                            tag.length > 20
                              ? _c(
                                  "a-tooltip",
                                  { key: tag, attrs: { title: tag } },
                                  [
                                    _c(
                                      "a-tag",
                                      {
                                        key: tag,
                                        attrs: {
                                          closable: index !== 0,
                                          afterClose: function() {
                                            return _vm.handleTagClose(tag)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tag.slice(0, 20) + "...") +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "a-tag",
                                  {
                                    key: tag,
                                    attrs: {
                                      closable: index !== 0,
                                      afterClose: function() {
                                        return _vm.handleTagClose(tag)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag))]
                                )
                          ]
                        }),
                        _vm.tagInputVisible
                          ? _c("a-input", {
                              ref: "tagInput",
                              style: { width: "78px" },
                              attrs: {
                                type: "text",
                                size: "small",
                                value: _vm.tagInputValue
                              },
                              on: {
                                change: _vm.handleInputChange,
                                blur: _vm.handleTagInputConfirm,
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleTagInputConfirm($event)
                                }
                              }
                            })
                          : _c(
                              "a-tag",
                              {
                                staticStyle: {
                                  background: "#fff",
                                  borderStyle: "dashed"
                                },
                                on: { click: _vm.showTagInput }
                              },
                              [
                                _c("a-icon", { attrs: { type: "plus" } }),
                                _vm._v(" New Tag\n            ")
                              ],
                              1
                            )
                      ],
                      2
                    )
                  ]),
                  _c("a-divider", { attrs: { dashed: true } }),
                  _c(
                    "div",
                    { staticClass: "account-center-team" },
                    [
                      _c("div", { staticClass: "teamTitle" }, [_vm._v("团队")]),
                      _c("a-spin", { attrs: { spinning: _vm.teamSpinning } }, [
                        _c(
                          "div",
                          { staticClass: "members" },
                          [
                            _c(
                              "a-row",
                              _vm._l(_vm.teams, function(item, index) {
                                return _c(
                                  "a-col",
                                  { key: index, attrs: { span: 12 } },
                                  [
                                    _c(
                                      "a",
                                      [
                                        _c("a-avatar", {
                                          attrs: {
                                            size: "small",
                                            src: item.avatar
                                          }
                                        }),
                                        _c("span", { staticClass: "member" }, [
                                          _vm._v(_vm._s(item.name))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { md: 24, lg: 17 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    bordered: false,
                    tabList: _vm.tabListNoTitle,
                    activeTabKey: _vm.noTitleKey
                  },
                  on: {
                    tabChange: function(key) {
                      return _vm.handleTabChange(key, "noTitleKey")
                    }
                  }
                },
                [
                  _vm.noTitleKey === "article"
                    ? _c("article-page")
                    : _vm.noTitleKey === "app"
                    ? _c("app-page")
                    : _vm.noTitleKey === "project"
                    ? _c("project-page")
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }