var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "j-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        maskClosable: false,
        switchFullscreen: "",
        okButtonProps: { class: { "jee-hidden": _vm.disableSubmit } }
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c("assets-form", {
        ref: "realForm",
        attrs: { disabled: _vm.disableSubmit },
        on: { ok: _vm.submitCallback }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }