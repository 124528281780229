var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("a-table", {
        attrs: {
          rowKey: "rowIndex",
          bordered: "",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination
        },
        on: { change: _vm.handleTableChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }