<template>

  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="评分" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="source">
              <a-input v-model="model.source" placeholder="请输入评分"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="评语" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="comment">
              <a-textarea v-model="model.comment" rows="4" placeholder="请输入评语" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JUpload from '@/components/jeecg/JUpload'
  import store from '@/store'
  export default {
    name: 'TaskForm2',
    components: {
    JUpload,
  },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      },
      reportId: {
        type: String,
        default: '',
        required: false
      },
      record: {
        type: Object,
        default: {},
        required: false
      }
    },
    data () {
      return {
        store,
        categoryList:[],
        classData:[],
        model:{
          id:'',
          source:'',
          comment:''
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
           source: [
              { required: true, message: '请输入评分!'},
           ],
           comment: [
              { required: true, message: '请输入批注!'},
           ],
        },
        url: {
          comment: "system/experimentalReport/comment"
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      viewNews(record){
        getAction(this.url.newsView,{id:record.id}).then((res)=>{
             record.classes = res.result.classes;
             record.asstes = res.result.asstes;
             record.resources = res.result.resources;
             this.model = Object.assign({}, record);
          })
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            httpurl+=this.url.comment;
            method = 'put';
            httpAction(httpurl,{id:this.reportId,source:this.model.source,comment:this.model.comment},method).then((res)=>{
              if(res.success){
                that.$message.success("批注成功");
                this.$emit('ok')
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },getClass(name) {
       // var userId = store.getters.userInfo.id;
      httpAction(`${this.url.classPulldownUrl}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.classData=res.result;
            this.classData.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },renderCategory() {
      let that = this
      httpAction(`${this.url.categoryPulldownUrl}?name=`, {}, 'get')
        .then((res) => {
          if (res.success) {
            res.result.forEach((item)=>{
              item.value=item.id;
              item.label=item.name;
              //item.text=item.name;
            })
            this.categoryList=res.result;
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },
    handleSearch(value) {
      this.getClass(value)
    }
    }
  }
</script>