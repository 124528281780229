var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.model, rules: _vm.rules },
          on: { submit: _vm.handleSubmit }
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "Note",
                        prop: "note",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 }
                      }
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.model.note,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "note", $$v)
                          },
                          expression: "model.note"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "Gender",
                        prop: "gender",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            placeholder:
                              "Select a option and change input text above"
                          },
                          on: { change: _vm.handleSelectChange },
                          model: {
                            value: _vm.model.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "gender", $$v)
                            },
                            expression: "model.gender"
                          }
                        },
                        [
                          _c("a-select-option", { attrs: { value: "male" } }, [
                            _vm._v("male")
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "female" } },
                            [_vm._v("female")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "Gender",
                        prop: "cascader",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 }
                      }
                    },
                    [
                      _c("a-cascader", {
                        attrs: {
                          options: _vm.areaOptions,
                          showSearch: { filter: _vm.filter },
                          placeholder: "Please select"
                        },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { wrapperCol: { span: 12, offset: 5 } } },
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { wrapperCol: { span: 12, offset: 5 } } },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", htmlType: "submit" } },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }