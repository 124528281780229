var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "编码",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "number"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入编码" },
                            model: {
                              value: _vm.model.number,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "number", $$v)
                              },
                              expression: "model.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "mac地址",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "macAddr"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入mac地址" },
                            model: {
                              value: _vm.model.macAddr,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "macAddr", $$v)
                              },
                              expression: "model.macAddr"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态:2已用，1启用，0停用",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "status"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态:2已用，1启用，0停用"
                            },
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }