var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "标题",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "title"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入标题" },
                            model: {
                              value: _vm.model.title,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "title", $$v)
                              },
                              expression: "model.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "备注",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "remark"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入备注" },
                            model: {
                              value: _vm.model.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "remark", $$v)
                              },
                              expression: "model.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态:0正常，1已审批",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "status"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入状态:0正常，1已审批" },
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }