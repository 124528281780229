var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("j-editable-table", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.dataSource,
      rowNumber: true,
      rowSelection: true,
      maxHeight: 400,
      disabled: true
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }