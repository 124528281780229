var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticStyle: {
        width: "130%",
        "text-align": "center",
        "margin-left": "-10%"
      },
      attrs: { bordered: false }
    },
    [
      _c(
        "a-steps",
        { staticClass: "steps", attrs: { current: _vm.currentTab } },
        [
          _c("a-step", { attrs: { title: "账号信息填写" } }),
          _c("a-step", { attrs: { title: "完成" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.currentTab === 0
            ? _c("step2", { on: { nextStep: _vm.nextStep } })
            : _vm._e(),
          _vm.currentTab === 2
            ? _c("step4", {
                attrs: { userList: _vm.userList },
                on: { prevStep: _vm.prevStep, finish: _vm.finish }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }