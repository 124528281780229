var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "受理量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.sll)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-area", {
                        attrs: { dataSource: _vm.chartData.sll }
                      })
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("今日受理量："),
                    _c("span", [_vm._v(_vm._s(_vm.todaySll))])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "办结量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.bjl)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-area", {
                        attrs: { dataSource: _vm.chartData.bjl }
                      })
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("今日办结量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayBjl))])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "用户受理量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.isll)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-bar", {
                        attrs: { dataSource: _vm.chartData.isll }
                      })
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("用户今日受理量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayISll))])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "用户办结量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.ibjl)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-bar", {
                        attrs: { dataSource: _vm.chartData.ibjl }
                      })
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("用户今日办结量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayIBjl))])
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card",
        {
          attrs: {
            loading: _vm.loading,
            bordered: false,
            "body-style": { padding: "0" }
          }
        },
        [
          _c(
            "div",
            { staticClass: "salesCard" },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": "1",
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px"
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrapper",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent"
                    },
                    [
                      _c("div", { staticClass: "extra-item" }, [
                        _c("a", [_vm._v("今日")]),
                        _c("a", [_vm._v("本周")]),
                        _c("a", [_vm._v("本月")]),
                        _c("a", [_vm._v("本年")])
                      ]),
                      _c("a-range-picker", { style: { width: "256px" } })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { loading: "true", tab: "受理监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [_c("bar", { attrs: { title: "受理量统计" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(_vm.registerTypeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "a-col",
                                            {
                                              key: "registerType" + index,
                                              class: "more-btn",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                    ghost: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goPage(index)
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "交互监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("bar-multid", {
                                attrs: {
                                  dataSource: _vm.jhjgData,
                                  fields: _vm.jhjgFields,
                                  title: "平台与部门交互量统计"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(_vm.registerTypeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "a-col",
                                            {
                                              key: "registerType" + index,
                                              class: "more-btn",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                    ghost: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goPage(index)
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "3", attrs: { tab: "效率监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("line-chart-multid", {
                                attrs: {
                                  dataSource: _vm.xljgData,
                                  fields: _vm.xljgFields,
                                  title: "平台与部门交互效率统计"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(_vm.registerTypeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "a-col",
                                            {
                                              key: "registerType" + index,
                                              class: "more-btn",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                    ghost: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goPage(index)
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "4", attrs: { tab: "存储监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c(
                                "a-row",
                                [
                                  _vm.diskInfo && _vm.diskInfo.length > 0
                                    ? _vm._l(_vm.diskInfo, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "a-col",
                                          {
                                            key: "diskInfo" + index,
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _c("dash-chart-demo", {
                                              attrs: {
                                                title: item.name,
                                                dataSource: item.restPPT
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(_vm.registerTypeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "a-col",
                                            {
                                              key: "registerType" + index,
                                              class: "more-btn",
                                              attrs: { span: 10 }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                    ghost: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goPage(index)
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "a-card",
            {
              class: { "anty-list-cust": true },
              style: { marginTop: "24px" },
              attrs: { loading: _vm.loading, bordered: false }
            },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": "1",
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px"
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrapper",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent"
                    },
                    [
                      _c(
                        "a-radio-group",
                        { attrs: { defaultValue: "1" } },
                        [
                          _c("a-radio-button", { attrs: { value: "1" } }, [
                            _vm._v("转移登记")
                          ]),
                          _c("a-radio-button", { attrs: { value: "2" } }, [
                            _vm._v("抵押登记")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      attrs: { loading: "true", tab: "业务流程限时监管" }
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          dataSource: _vm.dataSource1,
                          size: "default",
                          rowKey: "id",
                          columns: _vm.columns,
                          pagination: _vm.ipagination
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "flowRate",
                            fn: function(text, record, index) {
                              return [
                                _c("a-progress", {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    percent: _vm.getFlowRateNumber(
                                      record.flowRate
                                    )
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { loading: "true", tab: "业务节点限时监管" }
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          dataSource: _vm.dataSource2,
                          size: "default",
                          rowKey: "id",
                          columns: _vm.columns2,
                          pagination: _vm.ipagination
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "flowRate",
                            fn: function(text, record, index) {
                              return [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(record.flowRate) + "分钟")
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }