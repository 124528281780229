import { render, staticRenderFns } from "./RedisInfo.vue?vue&type=template&id=1fbddf02&"
import script from "./RedisInfo.vue?vue&type=script&lang=js&"
export * from "./RedisInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fbddf02')) {
      api.createRecord('1fbddf02', component.options)
    } else {
      api.reload('1fbddf02', component.options)
    }
    module.hot.accept("./RedisInfo.vue?vue&type=template&id=1fbddf02&", function () {
      api.rerender('1fbddf02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/modules/monitor/RedisInfo.vue"
export default component.exports