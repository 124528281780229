<template>
  <div class="index">
    <div class="banner">
      <div class="box">
        <div class="left">
          <p class="font1">让我们的实验实践教学</p>
          <p class="font2">焕发新的活力</p>
          <a-button type="primary" @click="yuyue" class="demonstrate-btn"
            ><img class="demonstrate" src="../../../assets/newIndex/demonstrate.png" alt="logo" />预约演示</a-button
          >
        </div>
        <img class="right" src="../../../assets/newIndex/banner-right.png" alt="logo" />
      </div>
    </div>
    <div class="explain">
      <ul>
        <li>
          <div>
            <img class="explain-logo" src="../../../assets/newIndex/explain-user.png" alt="logo" />
            <p class="title">三类人群</p>
            <p class="title1">管理员、教师、学生</p>
          </div>
        </li>
        <li>
          <div>
            <img class="explain-logo" src="../../../assets/newIndex/explain-phone.png" alt="logo" />
            <p class="title">小程序</p>
            <p class="title1">便携服务贴身服务</p>
          </div>
        </li>
        <li>
          <div>
            <img class="explain-logo" src="../../../assets/newIndex/explain-pc.png" alt="logo" />
            <p class="title">管理后台</p>
            <p class="title1">管好系统数据</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="scrollBox">
      <vue-seamless-scroll :data="imagesList" :class-option="optionLeft" class="seamless-warp2">
        <ul class="index_footer">
          <li class="footer_menu">
            <img src="../../../assets/newIndex/hbkj.png" width="230" height="60" class="footer_banner" alt="" />
          </li>
          <li class="footer_menu">
            <img src="../../../assets/newIndex/bh.png" class="footer_banner" alt="" />
          </li>
          <li class="footer_menu">
            <img src="../../../assets/newIndex/hbdx.png" class="footer_banner" alt="" />
          </li>
          <li class="footer_menu">
            <img src="../../../assets/newIndex/nk.png" class="footer_banner" alt="" />
          </li>
          <li class="footer_menu">
            <img src="../../../assets/newIndex/bh.png" class="footer_banner" alt="" />
          </li>
          <li class="footer_menu">
            <img src="../../../assets/newIndex/jlxy.png" class="footer_banner" alt="" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
    <div>
      <a-carousel autoplay>
        <div class="carouselBox">
          <div class="item">
            <div class="left">
              <div class="left_header">
                <p class="text">
                  Anytime<br />
                  Anywhere
                </p>
                <img src="../../../assets/newIndex/line.png" class="header_line" alt="" />
              </div>
              <p class="text2">每一个学生随时随地能看到那些设备能借用</p>
              <p class="text3">不会让学习的热情因为等待太久而浇灭</p>
            </div>
            <img src="../../../assets/newIndex/mobile3.png" class="carousel_mobile" alt="" />
          </div>
        </div>
        <div class="carouselBox">
          <div class="item">
            <div class="left">
              <div class="left_header">
                <p class="text">
                  Everyday<br />
                  Review
                </p>
                <img src="../../../assets/newIndex/line.png" class="header_line" alt="" />
              </div>
              <p class="text2">记录我们实验实践教学的每一步变化与成长</p>
              <p class="text3">关注实验报告的提交，更关注记录实践能力的提升</p>
            </div>
            <img src="../../../assets/newIndex/mobile2.png" class="carousel_mobile" alt="" />
          </div>
        </div>
        <div class="carouselBox">
          <div class="item">
            <div class="left">
              <div class="left_header">
                <p class="text">
                  Notice<br />
                  Board
                </p>
                <img src="../../../assets/newIndex/line.png" class="header_line" alt="" />
              </div>
              <p class="text2">小小公告栏，安全教育、竞赛宣传、主题教育一个都不能少</p>
              <p class="text3">通过小程序发布内部宣传通告，传播触达度perfect</p>
            </div>
            <img src="../../../assets/newIndex/mobile1.png" class="carousel_mobile" alt="" />
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="content1">
      <div class="box">
        <div class="left_box">
          <div class="title1">物联网与AI技术的助力 实验实践设备管理</div>
          <div class="describe">智能设备管理柜利用物联网智能锁，配合系统软件实现后台管理端+多个小程序端</div>
          <ul class="characteristic">
            <li><img src="../../../assets/newIndex/characteristic.png" class="img" alt="" />可用性</li>
            <li><img src="../../../assets/newIndex/characteristic.png" class="img" alt="" />安全性</li>
            <li><img src="../../../assets/newIndex/characteristic.png" class="img" alt="" />快速搭建</li>
            <li><img src="../../../assets/newIndex/characteristic.png" class="img" alt="" />灵活扩展</li>
          </ul>
        </div>
        <div class="right_img">
          <img src="../../../assets/newIndex/content1_right.png" class="right1" alt="" />
        </div>
      </div>
    </div>
    <div class="content2">
      <ul>
        <li @click="content2Click(1)" :class="active1 == 1 ? 'active' : ''">
          <div v-if="active1 != 1">
            <p class="describe">分权限角色<br />管理</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_2.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">分权限角色管理</div>
            <div class="active_line"></div>
            <div class="active_describe">
              系统区分管理员、教师、学生角色，并对相应角色采用不同的权限赋权，严控管理黑与流程死角
            </div>
          </div>
        </li>
        <li @click="content2Click(2)" :class="active1 == 2 ? 'active' : ''">
          <div v-if="active1 != 2">
            <p class="describe">数据可视化<br />管理</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_2.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">数据可视化管理</div>
            <div class="active_line"></div>
            <div class="active_describe">
              设备全生命周期的数据可视化管理，从录入设备上架的那一刻起，实验室设备的借用归还情况就实时记录在册
            </div>
          </div>
        </li>
        <li @click="content2Click(3)" :class="active1 == 3 ? 'active' : ''">
          <div v-if="active1 != 3">
            <p class="describe">自动化<br />管理</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_3.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">自动化管理</div>
            <div class="active_line"></div>
            <div class="active_describe">
              实验设备在上架入柜的之后，不需要重复对设备进行上下架与借用登记，实现全流程的一次上架周期内免维护
            </div>
          </div>
        </li>
        <li @click="content2Click(4)" :class="active1 == 4 ? 'active' : ''">
          <div v-if="active1 != 4">
            <p class="describe">辅助线上<br />线下融合<br />教学</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_4.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">辅助线上线下融合教学</div>
            <div class="active_line"></div>
            <div class="active_describe">
              从借用设备到提交实验报告统一到一个平台，能够实现疫情下非聚集的实验教学管理，助力线上线下融合教学
            </div>
          </div>
        </li>
        <li @click="content2Click(5)" :class="active1 == 5 ? 'active' : ''">
          <div v-if="active1 != 5">
            <p class="describe">随时随地<br />发起借用</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_5.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">随时随地发起借用</div>
            <div class="active_line"></div>
            <div class="active_describe">
              用小程序搜索可用实验设备，无需到达柜体所在位置，高效便捷使用
            </div>
          </div>
        </li>
        <li @click="content2Click(6)" :class="active1 == 6 ? 'active' : ''">
          <div v-if="active1 != 6">
            <p class="describe">非接触<br />借用</p>
            <div class="right_img">
              <img src="../../../assets/newIndex/content2_5.png" class="bottom_img" alt="" />
            </div>
          </div>
          <div class="active_box" v-else>
            <div class="active_title">非接触借用</div>
            <div class="active_line"></div>
            <div class="active_describe">
              柜体无广告屏，减低了设备故障率；全流程点击触屏接触，降低了疫情风险传播，更节能省电
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="content3">
      <div class="footer_box"></div>
      <div class="title">支持高校实验室常见设备设施入柜通用性强</div>
      <div class="describe">能容万物</div>
      <ul>
        <li @click="content3Click(1)" :class="active2 == 1 ? 'li_active' : ''">
          <img src="../../../assets/newIndex/content3_1.png" alt="" class="img_logo" />
          <div class="li_title">电子元器件</div>
          <div :class="active2 == 1 ? 'li_footer' : 'li_active1 li_footer'">
            <div class="li_describe">开发板、传感器模块 、电池电机、辅助线材、基础材料等</div>
          </div>
        </li>
        <li @click="content3Click(2)" :class="active2 == 2 ? 'li_active' : ''">
          <img src="../../../assets/newIndex/content3_2.png" alt="" class="img_logo" />
          <div class="li_title">电工电子实验仪器</div>
          <div :class="active2 == 2 ? 'li_footer' : 'li_active1 li_footer'">
            <div class="li_describe">示波器、万用表、焊台、教学实验箱，开发套件等</div>
          </div>
        </li>
        <li @click="content3Click(3)" :class="active2 == 3 ? 'li_active' : ''">
          <img src="../../../assets/newIndex/content3_3.png" alt="" class="img_logo" />
          <div class="li_title">机械装备</div>
          <div :class="active2 == 3 ? 'li_footer' : 'li_active1 li_footer'">
            <div class="li_describe">五金配件、钳子、 量具、刀具、3D打印设备等</div>
          </div>
        </li>
        <li @click="content3Click(4)" :class="active2 == 4 ? 'li_active' : ''">
          <img src="../../../assets/newIndex/content3_4.png" alt="" class="img_logo" />
          <div class="li_title">通用办公设备</div>
          <div :class="active2 == 4 ? 'li_footer' : 'li_active1 li_footer'">
            <div class="li_describe">电脑、硬盘、DV摄像机、U盘、教学平板等</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="content4">
      <ul class="content4_header">
        <li>
          <div class="txt active" @click="content4Click(1)">场景</div>
          <div class="line" v-if="active3 == 1"></div>
        </li>
        <li>
          <div class="txt" @click="content4Click(2)">角色</div>
          <div class="line" v-if="active3 == 2"></div>
        </li>
        <li>
          <div class="txt" @click="content4Click(3)">设备</div>
          <div class="line" v-if="active3 == 3"></div>
        </li>
      </ul>
      <ul class="content4_content" v-if="active3 == 1">
        <li :class="active3Child == 1 ? 'content4_content_active' : ''" @click="content4ChildClick(1)">
          <img v-if="active3Child != 1" src="../../../assets/newIndex/content4_1_1.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 1"
            src="../../../assets/newIndex/content4_1_1_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">专业实验室</div>
          <div class="li_describe">实验设备智能<br />管理管控</div>
        </li>
        <li :class="active3Child == 2 ? 'content4_content_active' : ''" @click="content4ChildClick(2)">
          <img v-if="active3Child != 2" src="../../../assets/newIndex/content4_1_2.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 2"
            src="../../../assets/newIndex/content4_1_2_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">电工电子中心</div>
          <div class="li_describe">常见电工电子仪器的<br />开放共享</div>
        </li>
        <li :class="active3Child == 3 ? 'content4_content_active' : ''" @click="content4ChildClick(3)">
          <img v-if="active3Child != 3" src="../../../assets/newIndex/content4_1_3.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 3"
            src="../../../assets/newIndex/content4_1_3_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">工程训练中心</div>
          <div class="li_describe">五金易耗低值工具的<br />使用记录</div>
        </li>
      </ul>
      <ul class="content4_content" v-if="active3 == 2">
        <li :class="active3Child == 1 ? 'content4_content_active' : ''" @click="content4ChildClick(1)">
          <img v-if="active3Child != 1" src="../../../assets/newIndex/content4_2_1.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 1"
            src="../../../assets/newIndex/content4_2_1_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">学生</div>
          <div class="li_describe">更方便的借用<br />实验实践设备</div>
        </li>
        <li :class="active3Child == 2 ? 'content4_content_active' : ''" @click="content4ChildClick(2)">
          <img v-if="active3Child != 2" src="../../../assets/newIndex/content4_2_2.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 2"
            src="../../../assets/newIndex/content4_2_2_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">实验室管理员</div>
          <div class="li_describe">降低管理劳动强度，周期内免予设备维护</div>
        </li>
        <li :class="active3Child == 3 ? 'content4_content_active' : ''" @click="content4ChildClick(3)">
          <img v-if="active3Child != 3" src="../../../assets/newIndex/content4_2_3.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 3"
            src="../../../assets/newIndex/content4_2_3_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">中心主任</div>
          <div class="li_describe">完美记录了实验设备的使用记录，提升了实验室资产的设备使用绩效</div>
        </li>
        <li :class="active3Child == 4 ? 'content4_content_active' : ''" @click="content4ChildClick(4)">
          <img v-if="active3Child != 4" src="../../../assets/newIndex/content4_2_4.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 4"
            src="../../../assets/newIndex/content4_2_4_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">实验设备处</div>
          <div class="li_describe">为设备设施的紧急采购和延缓采购提供了依据，提升了资金的有效利用率</div>
        </li>
      </ul>
      <ul class="content4_content" v-if="active3 == 3">
        <li :class="active3Child == 1 ? 'content4_content_active' : ''" @click="content4ChildClick(1)">
          <img v-if="active3Child != 1" src="../../../assets/newIndex/content4_3_1.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 1"
            src="../../../assets/newIndex/content4_3_1_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">基础电工电子类</div>
        </li>
        <li :class="active3Child == 2 ? 'content4_content_active' : ''" @click="content4ChildClick(2)">
          <img v-if="active3Child != 2" src="../../../assets/newIndex/content4_3_2.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 2"
            src="../../../assets/newIndex/content4_3_2_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">元器件</div>
        </li>
        <li :class="active3Child == 3 ? 'content4_content_active' : ''" @click="content4ChildClick(3)">
          <img v-if="active3Child != 3" src="../../../assets/newIndex/content4_3_3.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 3"
            src="../../../assets/newIndex/content4_3_3_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">机械装备</div>
        </li>
        <li :class="active3Child == 4 ? 'content4_content_active' : ''" @click="content4ChildClick(4)">
          <img v-if="active3Child != 4" src="../../../assets/newIndex/content4_3_4.png" alt="" class="img_logo" />
          <img
            v-if="active3Child == 4"
            src="../../../assets/newIndex/content4_3_4_active.png"
            alt=""
            class="img_logo"
          />
          <div class="li_title">通用办公设备</div>
        </li>
      </ul>
    </div>
    <div class="content5">
      <div class="content5_box">
        <div class="tit">开始使用毅力实验通</div>
        <div class="desc">一起完成目标，让进步发生。</div>
        <div class="footer_action">
          <div class="f_left" @click="login">立即登录</div>
          <div class="f_right" @click="yuyue">
            <img class="img" src="../../../assets/newIndex/action.png" alt="logo" />预约演示
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueSeamless from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamless,
  },
  data() {
    return {
      active1: '1',
      active2: '1',
      active3: '1',
      active3Child: '1',
      active: '',
      imagesList:[
        {
src:"../../../assets/newIndex/hbkj.png"
        },
        {
         src:"../../../assets/newIndex/bh.png" 
        },
      ],
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      }
    },
  },
  methods: {
    handleClose() {
      this.visible = false
    },
    handleTabClick(key) {
      this.active = key
    },
    yuyue() {
      this.$parent.handleTabClick('yy')
    },
    login() {
      this.$router.push({ path: '/user/login' });
    },
    content2Click(flag) {
      console.log(flag)
      this.active1 = flag
    },
    content3Click(flag) {
      this.active2 = flag
    },
    content4Click(flag) {
      this.active3 = flag
    },
    content4ChildClick(flag) {
      this.active3Child = flag
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  height: 100%;
  width: 100%;
  background: #fff !important;
  .header {
    background-color: #bbf5e8;
    height: 70px;
    .box {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      align-items: end;
      position: relative;
      .logo {
        width: 166px;
        height: 31px;
        margin-bottom: 20px;
      }
      ul {
        display: flex;
        margin: 0;
        height: 45px;
        padding-right: 130px;
        .btn-login {
          position: absolute !important;
          top: 17px;
          right: 10px;
          button {
            background: #eb4d27;
          }
        }
        li {
          cursor: pointer;
          position: relative;
          list-style: none;
          margin: 0px 30px;
          .login {
          }
          .line {
            position: absolute;
            bottom: 0;
            width: 12px;
            height: 3px;
            background: #eb4d27;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            left: 35%;
          }
        }
      }
    }
  }
  .banner {
    background-image: url('../../../assets/newIndex/banner.png');
    height: 618px;
    max-width: 1920px;
    margin: 0 auto;
    .box {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      .left {
        position: absolute;
        left: 0;
        top: 149px;
        .font1 {
          font-size: 60px;
          color: #fff;
          font-weight: 100;
          margin-bottom: 0;
        }
        .font2 {
          font-size: 60px;
          color: #fff;
        }
        .demonstrate-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eb4d27;
          .demonstrate {
            width: 12.5px;
            height: 12.5px;
            margin-right: 5px;
          }
        }
      }
      .right {
        width: 799px;
        height: 531px;
        margin-top: 19px;
        float: right;
      }
    }
  }
  .explain {
    width: 1200px;
    height: 341px;
    background: #fff;
    margin: -87px auto;
    z-index: 999;
    position: relative;
    border-radius: 8px 8px 8px 8px;
    ul {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        width: 200px;
        list-style: none;
        text-align: center;
        .explain-logo {
          width: 142px;
          height: 142px;
        }
        .title {
          font-size: 30px;
          font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
        }
        .title1 {
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #999999;
        }
        p {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .seamless-warp2 {
    display: inline-block;
    height: 100px;
    width: 100% !important;
    overflow: hidden;
    ul.item {
      width: 580px;
      li {
        float: left;
        margin-right: 10px;
      }
    }
    .index_footer {
      align-items: baseline;
    }

    .footer_menu,
    .index_footer {
      display: flex;
      justify-content: center;
    }

    .footer_menu {
      flex-direction: column;
      align-items: center;
      margin-left: 10px;
      height: 100px;
      min-width: 260px;
    }

    .footer_banner {
      min-width: 230px;
      min-height: 60px;
    }
  }
  .scrollBox {
    margin-top: 87px;
    text-align: center;
  }
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-slide h3) {
    color: #fff;
  }
  .carousel_mobile {
    height: 400px;
    width: 200px;
  }
  .carouselBox {
    margin-top: 50px;
    .item {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      .left {
        margin-right: 235px;
      }
    }
    .left_header {
      display: flex;
      align-items: flex-start;
      height: 100px;
      .text {
        min-width: 194px;
        height: 97px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
        line-height: 41px;
      }
      .header_line {
        width: 162px;
        height: 72px;
      }
    }

    .text2 {
      min-width: 337px;
      height: 25px;
      font-size: 18px;
      font-weight: normal;
      color: #999999;
      line-height: 23px;
    }
    .text3 {
      min-width: 368px;
      height: 30px;
      font-size: 22px;
      font-weight: normal;
      color: #666666;
      line-height: 25px;
    }
  }
  .content1 {
    display: flex;
    max-width: 1920px;
    height: 405px;
    margin: 148px auto 0 auto;
    background: linear-gradient(325deg, #504c4b 0%, #ffab39 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    .box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      .title1 {
        width: 400px;
        height: 102px;
        font-size: 40px;
        font-weight: normal;
        color: #ffffff;
        line-height: 46px;
      }
      .characteristic {
        margin-top: 63px;
        padding-left: 0;
        display: flex;
        li {
          .img {
            margin-right: 14px;
          }
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
          line-height: 26px;
          margin-right: 37px;
        }
      }
      .describe {
        width: 403px;
        height: 48px;
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        line-height: 26px;
      }
      .left_box {
        padding-top: 76px;
      }
      .right_img {
        margin-left: 50px;
      }
      .right1 {
        max-width: 528px;
        min-width: 428px;
        height: 405px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
  .content2 {
    max-width: 1920px;
    height: 543px;
    background: url('../../../assets/newIndex/content2.png');
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 0 auto;
    ul {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      li {
        cursor: pointer;
        height: 311px;
        width: 161px;
        margin-right: 10px;
        background: #fff;
        margin-top: 115px;
        border-radius: 8px 8px 8px 8px;
        position: relative;
        .describe {
          padding-top: 49px;
          padding-left: 32px;
        }
        .right_img {
          position: absolute;
          bottom: 47px;
          left: 32px;
          width: 55px;
          height: 55px;
          line-height: 55px;
          text-align: center;
          border: 1px solid #f9c9bd;
          border-radius: 50%;
        }
        .active_box {
          padding-left: 45px;
          .active_title {
            min-width: 152px;
            height: 30px;
            font-size: 22px;
            font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            margin-top: 43px;
          }
          .active_line {
            margin-top: 19px;
            margin-left: 1px;
            width: 20px;
            height: 4px;
            background: #ffffff;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
          }
          .active_describe {
            width: 257px;
            height: 64px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            line-height: 22px;
            margin-top: 86px;
          }
        }
      }
      .active {
        width: 345px;
        height: 311px;
        background-image: url('../../../assets/newIndex/bk_active.png');
        width: 345px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }
    }
  }
  .content3 {
    max-width: 1920px;
    height: 827px;
    background: url('../../../assets/newIndex/content3.png');
    position: relative;
    margin: 0 auto;
    .footer_box {
      position: absolute;
      bottom: 0;
    }
    .title {
      padding-top: 130px;
      font-size: 40px;
      font-weight: normal;
      color: #fdf1e9;
      line-height: 46px;
      height: 180px;
      width: 1200px;
      text-align: center;
      margin:0 auto;
    }
    .describe {
      margin-top: 15px;
      width: 722px;
      height: 28px;
      font-size: 20px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #fdf1e9;
      line-height: 26px;
      width: 1200px;
      margin: 15px auto;
      text-align: center;
    }
    ul {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      margin-top: 152px;
      li {
        width: 293px;
        height: 388px;
        background: #ffffff;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        margin-right: 10px;
        display: flex;
        align-items: center !important;
        flex-direction: column;

        .img_logo {
          width: 120px;
          height: 163px;
          padding-top: 43px;
        }
        .li_title {
          min-width: 142px;
          height: 33px;
          font-size: 24px;
          font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #eb4d27;
          line-height: 38px;
          margin-top: 28px;
        }
        .li_footer {
          width: 100%;
          height: 164px;
          margin-top: 48px;
          .li_describe {
            width: 144px;
            height: 62px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #999999;
            line-height: 21px;
            margin: 0 auto;
            text-align: center;
          }
        }
        .li_active1 {
          background: linear-gradient(180deg, rgba(81, 218, 190, 0) 0%, #51dabe 100%);
          border-radius: 0px 0px 0px 0px;
          opacity: 0.42;
        }
      }
      .li_active {
        width: 293px;
        height: 450px;
        background: #ffffff;
        box-shadow: 0px -5px 23px 1px rgba(84, 255, 203, 0.88);
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        border: 2px solid #54ffcb;
        z-index: 999;
      }
    }
  }
  .content4 {
    max-width: 1920px;
    height: 799px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 0 auto;
    .content4_header {
      padding-top: 179px;
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      li {
        cursor: pointer;
        min-width: 79px;
        height: 56px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
        font-weight: normal;

        // line-height: 108px;
        margin-left: 68px;
        .txt {
          color: #999999;
        }
        .active {
          color: #000000;
        }
        .line {
          margin-top: 7px;
          width: 68px;
          height: 8px;
          background: linear-gradient(90deg, #14d0b4 0%, #66ddc1 100%);
          box-shadow: 0px 3px 6px 1px rgba(20, 208, 180, 0.27);
          border-radius: 188px 188px 188px 188px;
          opacity: 1;
        }
      }
    }
    .content4_content {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      margin-top: 69px;
      .content4_content_active {
        background: #ffffff;
        box-shadow: 0px 19px 27px 1px rgba(235, 77, 39, 0.19);
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 2px solid #eb4d27;
        .li_title {
          color: #000000 !important;
        }
        .li_describe {
          color: #999999 !important;
        }
      }
      li {
        cursor: pointer;
        width: 267px;
        height: 304px;
        background: linear-gradient(180deg, #14d0b4 0%, #66ddc1 100%);
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img_logo {
          padding-top: 51px;
          height: 119px;
          width: 58px;
        }
        .li_title {
          margin-top: 30px;
          min-width: 48px;
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .li_describe {
          width: 178px;
          height: 44px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
          padding-top: 18px;
          text-align: center;
        }
      }
    }
  }
  .content5 {
    //content5.png
    max-width: 1920px;
    height: 558px;
    background: url('../../../assets/newIndex/content5.png');
    background-size: cover;
    margin: 0 auto;
    position: relative;
    .content5_box {
      position: absolute;
      left: 500px;
      top: 100px;
      width: 500px;
      height: 270px;
      padding-top: 50px;
      text-align: center;
      background: #fff;
      .tit {
        min-width: 354px;
        height: 56px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
      }
      .desc {
        margin-top: 5px;
        min-width: 230px;
        height: 25px;
        font-size: 18px;
        font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
      }
      .footer_action {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        .f_left {
          cursor: pointer;
          color: #ffffff;
          width: 100px;
          height: 50px;
          background: linear-gradient(90deg, #14d0b4 0%, #66ddc1 100%);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        .f_right {
          cursor: pointer;
          width: 131px;
          height: 50px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #999999;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 12.5px;
            height: 12.5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .footer {
    background: #f9fcfe;
    .line {
      width: 100%;
      height: 0px;
      opacity: 0.5;
      border: 1px solid rgba(112, 112, 112, 0.26);
    }

    .footer_box {
      width: 1200px;
      margin: 0 auto;
      height: 385px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      .footer_left {
        .logo {
          width: 220px;
          height: 98px;
          padding-top: 59px;
        }
        .logo_txt {
          min-width: 173px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #999999;
          line-height: 38px;
          margin-top: 10px;
        }
        .ewm {
          width: 109px;
          height: 109px;
          margin-top: 40px;
        }
        .ewm_txt {
          min-width: 110px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
          line-height: 38px;
          margin-top: 6px;
        }
      }
      .footer_right {
        margin-right: 100px;
        display: flex;
        .left {
          margin-right: 115px;
        }
        ul {
          padding-top: 58px;
          padding-left: 0;
          .ft1 {
            min-width: 63px;
            height: 22px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #000000;
            line-height: 38px;
            margin-bottom: 15px;
          }
          li {
            padding-top: 16px;
            min-width: 55px;
            height: 36px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #999999;
            line-height: 38px;
          }
        }
      }
    }

    .footer_bottom {
      width: 1200px;
      margin: 0 auto;

      ul {
        display: flex;
        justify-content: right;
        li {
          min-width: 63px;
          height: 17px;
          font-size: 12px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #a7a7a7;
          line-height: 38px;
          margin-right: 43px;
        }
      }
      ul li:last-child {
        margin-right: 0;
      }
    }
  }
}
ul li {
  list-style: none;
}
</style>