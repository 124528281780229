<template>
  <a-modal
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    destroyOnClose
    :visible="visible">
    <task-form2 ref="realForm" @ok="submitCallback" :disabled="disableSubmit" normal></task-form2>
    <template #footer>
        <a-button key="back" @click="close">关闭</a-button>
      </template>
  </a-modal>
</template>

<script>

  import TaskForm2 from './TaskForm2'
  
  export default {
    name: 'ReportModal1',
    components: {
      TaskForm2
    },
    data () {
      return {
        title:'',
        width:1200,
        visible: false,
        disableSubmit: false
      }
    },
    methods: {
      add () {
        this.visible=true
        this.$nextTick(()=>{
          this.$refs.realForm.add();
        })
      },
      edit (record) {
        console.log(1111)
        cosnole.log(record)
        this.visible=true
        this.$nextTick(()=>{
          this.$refs.realForm.edit(record);
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        this.$refs.realForm.submitForm();
      },
      submitCallback(){
        this.$emit('ok');
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>