var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _c("div", { staticClass: "header", attrs: { id: "header" } }, [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("../../assets/newIndex/yllogo.png"),
            alt: "logo",
            height: "31px"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("home")
            }
          }
        }),
        _c("ul", [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("home")
                }
              }
            },
            [
              _vm._v("\n          首页\n          "),
              _vm.active == "home"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("cabinet")
                }
              }
            },
            [
              _vm._v("\n          智能柜\n          "),
              _vm.active == "cabinet"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("programme")
                }
              }
            },
            [
              _vm._v("\n          解决方案\n          "),
              _vm.active == "programme"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("practice")
                }
              }
            },
            [
              _vm._v("\n          最佳实践\n          "),
              _vm.active == "practice"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("download")
                }
              }
            },
            [
              _vm._v("\n          即刻体验\n          "),
              _vm.active == "download"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTabClick("hope")
                }
              }
            },
            [
              _vm._v("\n          帮助中心\n          "),
              _vm.active == "hope"
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            { staticClass: "btn-login", on: { click: _vm.login } },
            [
              _c(
                "a-button",
                { staticClass: "login", attrs: { type: "primary" } },
                [_vm._v("登录")]
              )
            ],
            1
          )
        ])
      ])
    ]),
    _c(
      "div",
      { attrs: { id: "content" } },
      [
        _vm.active == "yy" ? _c("yy", { ref: "yy" }) : _vm._e(),
        _vm.active == "home" ? _c("home", { ref: "home" }) : _vm._e(),
        _vm.active == "cabinet" ? _c("cabinet", { ref: "cabinet" }) : _vm._e(),
        _vm.active == "programme"
          ? _c("programme", { ref: "programme" })
          : _vm._e(),
        _vm.active == "practice"
          ? _c("practice", { ref: "practice" })
          : _vm._e(),
        _vm.active == "download"
          ? _c("download", { ref: "download" })
          : _vm._e(),
        _vm.active == "hope"
          ? _c("hope", { ref: "hope", attrs: { active: _vm.active4 } })
          : _vm._e(),
        _vm.active == "contact" ? _c("contact", { ref: "contact" }) : _vm._e(),
        _vm.active == "ys" ? _c("ys", { ref: "ys" }) : _vm._e(),
        _vm.active == "fw" ? _c("fw", { ref: "fw" }) : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer_box" }, [
        _vm._m(0),
        _c("div", { staticClass: "footer_right" }, [
          _c("ul", { staticClass: "left" }, [
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2 ft1",
                  on: {
                    click: function($event) {
                      return _vm.handleTabClick("hope")
                    }
                  }
                },
                [_vm._v("服务支持")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2",
                  on: {
                    click: function($event) {
                      return _vm.handleTabhopeClick("hope", 1)
                    }
                  }
                },
                [_vm._v("培训与演示")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2",
                  on: {
                    click: function($event) {
                      return _vm.handleTabhopeClick("hope", 2)
                    }
                  }
                },
                [_vm._v("帮助中心")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2",
                  on: {
                    click: function($event) {
                      return _vm.handleTabhopeClick("hope", 3)
                    }
                  }
                },
                [_vm._v("视频小课堂")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2",
                  on: {
                    click: function($event) {
                      return _vm.handleTabhopeClick("hope", 4)
                    }
                  }
                },
                [_vm._v("下载中心")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f2",
                  on: {
                    click: function($event) {
                      return _vm.handleTabhopeClick("hope", 5)
                    }
                  }
                },
                [_vm._v("保修承诺")]
              )
            ])
          ]),
          _c("ul", { staticClass: "right" }, [
            _vm._m(1),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f3",
                  on: {
                    click: function($event) {
                      return _vm.handleTabClick("contact")
                    }
                  }
                },
                [_vm._v("联系我们")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f3",
                  on: {
                    click: function($event) {
                      return _vm.handleTabClick("ys")
                    }
                  }
                },
                [_vm._v("隐私条款")]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "f3",
                  on: {
                    click: function($event) {
                      return _vm.handleTabClick("fw")
                    }
                  }
                },
                [_vm._v("用户协议")]
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "line" }),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_left" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../assets/newIndex/footer_logo.png") }
      }),
      _c("p", { staticClass: "logo_txt" }, [_vm._v("智慧实验室设备管理工具")]),
      _c("img", {
        staticClass: "ewm",
        attrs: { src: require("../../assets/newIndex/ewm.png") }
      }),
      _c("p", { staticClass: "ewm_txt" }, [_vm._v("扫码关注公众号")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "f3 ft1" }, [_vm._v("毅力实验通")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_bottom" }, [
      _c("ul", { staticClass: "footer_bottom_ul" }, [
        _c("li", [_vm._v("©2023 毅力澎湃&德塔星技术")]),
        _c("li", [
          _c(
            "a",
            { attrs: { href: "https://beian.miit.gov.cn", target: "blank" } },
            [_vm._v("京ICP备15015852号-3")]
          )
        ]),
        _c("li", [_vm._v("北京毅力澎湃技术有限公司")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }