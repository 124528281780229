<template>
  <exception-page type="404" />
</template>

<script>
  import ExceptionPage from './ExceptionPage'
import { timeFix } from "@/utils/util"
  export default {
    components: {
      ExceptionPage
    },
     data() {
      return {
        timeFix: timeFix(),
        }
    },created() {
        this.$router.push({ path: "/login" }).catch(()=>{
              console.log('定位错误!')
              console.log('登录跳转首页出错,这个错误从哪里来的')
            })
            this.$notification.success({
              message: '欢迎',
              description: `${timeFix()}，欢迎回来`,
            });
    }
  }
</script>

<style scoped>

</style>