<template>
  <a-list
    itemLayout="horizontal"
    :dataSource="data"
  >

  </a-list>
</template>

<script>
  export default {
    data () {
      return {
        data: []
      }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>