var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-chart",
        { attrs: { forceFit: true, height: _vm.height, data: _vm.data } },
        [
          _c("v-coord", { attrs: { type: "rect", direction: "LB" } }),
          _c("v-tooltip"),
          _c("v-legend"),
          _c("v-axis", { attrs: { dataKey: "State", label: _vm.label } }),
          _c("v-stack-bar", {
            attrs: { position: "State*流程数量", color: "流程状态" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }