var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: "磁盘监控" } },
    [
      _vm.loading
        ? _c("a-skeleton", { attrs: { active: "" } })
        : _c(
            "a-row",
            [
              _vm.diskInfo && _vm.diskInfo.length > 0
                ? _vm._l(_vm.diskInfo, function(item, index) {
                    return _c(
                      "a-col",
                      { key: "diskInfo" + index, attrs: { span: 8 } },
                      [
                        _c("dash-chart-demo", {
                          attrs: { title: item.name, datasource: item.restPPT }
                        })
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }