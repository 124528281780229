import { render, staticRenderFns } from "./DashChartDemo.vue?vue&type=template&id=02d68dfe&"
import script from "./DashChartDemo.vue?vue&type=script&lang=js&"
export * from "./DashChartDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02d68dfe')) {
      api.createRecord('02d68dfe', component.options)
    } else {
      api.reload('02d68dfe', component.options)
    }
    module.hot.accept("./DashChartDemo.vue?vue&type=template&id=02d68dfe&", function () {
      api.rerender('02d68dfe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/chart/DashChartDemo.vue"
export default component.exports