var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-list", {
    attrs: { itemLayout: "horizontal", dataSource: _vm.data },
    scopedSlots: _vm._u([
      {
        key: "renderItem",
        fn: function(item, index) {
          return _c(
            "a-list-item",
            { key: index },
            [
              _c("a-list-item-meta", [
                _c("a", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _c(
                  "span",
                  { attrs: { slot: "description" }, slot: "description" },
                  [
                    _c("span", { staticClass: "security-list-description" }, [
                      _vm._v(_vm._s(item.description))
                    ]),
                    item.value ? _c("span", [_vm._v(" : ")]) : _vm._e(),
                    _c("span", { staticClass: "security-list-value" }, [
                      _vm._v(_vm._s(item.value))
                    ])
                  ]
                )
              ]),
              item.actions
                ? [
                    _c(
                      "a",
                      {
                        attrs: { slot: "actions" },
                        on: { click: item.actions.callback },
                        slot: "actions"
                      },
                      [_vm._v(_vm._s(item.actions.title))]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }