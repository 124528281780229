var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      attrs: {
        trigger: "click",
        placement: "bottomRight",
        overlayStyle: { width: "300px" }
      }
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loadding } },
            [
              _c(
                "a-tabs",
                _vm._l(_vm.tabs, function(tab, k) {
                  return _c("a-tab-pane", { key: k, attrs: { tab: tab.title } })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "header-notice", on: { click: _vm.fetchNotice } },
        [
          _c(
            "a-badge",
            { attrs: { count: "12" } },
            [
              _c("a-icon", {
                staticStyle: { "font-size": "16px", padding: "4px" },
                attrs: { type: "bell" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }