var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "用户列表",
        width: 1000,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading
      },
      on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel }
    },
    [
      _c("a-table", {
        ref: "table",
        attrs: {
          bordered: "",
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }