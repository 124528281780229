var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "类型",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "type"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "feedback_type",
                              placeholder: "请选择类型"
                            },
                            model: {
                              value: _vm.model.type,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "type", $$v)
                              },
                              expression: "model.type"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "描述",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "description"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: "4", placeholder: "请输入描述" },
                            model: {
                              value: _vm.model.description,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "description", $$v)
                              },
                              expression: "model.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "创建时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "createTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择创建时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.createTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "createTime", $$v)
                              },
                              expression: "model.createTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }