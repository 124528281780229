var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c("div", { staticClass: "staticBox" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("a-progress", {
              attrs: {
                type: "circle",
                percent: _vm.static.annotated,
                width: 80
              },
              scopedSlots: _vm._u([
                {
                  key: "format",
                  fn: function(percent) {
                    return [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(percent))
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("br"),
            _vm._v("已批注\n  ")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("a-progress", {
              attrs: {
                type: "circle",
                percent: _vm.static.completionRate,
                width: 80
              }
            }),
            _c("br"),
            _vm._v("完成率")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("a-progress", {
              attrs: {
                type: "circle",
                percent: _vm.static.excellenceRate,
                width: 80
              }
            }),
            _c("br"),
            _vm._v("优秀率")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("a-progress", {
              attrs: {
                type: "circle",
                percent: _vm.static.submitted,
                width: 80
              },
              scopedSlots: _vm._u([
                {
                  key: "format",
                  fn: function(percent) {
                    return [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(percent))
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("br"),
            _vm._v("已提交数")
          ],
          1
        )
      ]),
      _c(
        "a-tabs",
        {
          model: {
            value: _vm.activeKey,
            callback: function($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey"
          }
        },
        _vm._l(_vm.classData, function(item, index) {
          return _c("a-tab-pane", {
            key: item.id,
            attrs: { tab: item.name },
            on: {
              change: function($event) {
                return _vm.getReport(item.id)
              }
            }
          })
        }),
        1
      ),
      _c("a-table", {
        ref: "table",
        staticClass: "j-table-force-nowrap",
        attrs: {
          size: "middle",
          scroll: { x: true },
          bordered: "",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.reportData,
          pagination: _vm.ipagination
        },
        scopedSlots: _vm._u([
          {
            key: "htmlSlot",
            fn: function(text) {
              return [_c("div", { domProps: { innerHTML: _vm._s(text) } })]
            }
          },
          {
            key: "status",
            fn: function(text, record) {
              return [
                record.status == -1 ? _c("div", [_vm._v("待上传")]) : _vm._e(),
                record.status == 1 ? _c("div", [_vm._v("已上传")]) : _vm._e(),
                record.status == 2 ? _c("div", [_vm._v("已审批")]) : _vm._e()
              ]
            }
          },
          {
            key: "action",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openReport(record)
                        }
                      }
                    },
                    [_vm._v("详情")]
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  record.status != 2
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openAnnotations(record)
                            }
                          }
                        },
                        [_vm._v("批注")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.title,
            width: _vm.width,
            placement: "right",
            closable: false,
            okButtonProps: { class: { "jee-hidden": _vm.disableSubmit } },
            destroyOnClose: "",
            visible: _vm.visible
          },
          on: { close: _vm.close, ok: _vm.handleOk },
          model: {
            value: _vm.addDialog,
            callback: function($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog"
          }
        },
        [
          _c("task-form2", {
            ref: "realForm2",
            attrs: {
              reportId: _vm.reportId,
              record: _vm.record,
              disabled: _vm.disableSubmit,
              normal: ""
            },
            on: { ok: _vm.submitCallback }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "详情",
            width: _vm.width1,
            height: _vm.height1,
            placement: "right",
            closable: false,
            okButtonProps: { class: { "jee-hidden": _vm.disableSubmit } },
            destroyOnClose: "",
            visible: _vm.visible
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: {
                        click: function($event) {
                          _vm.addDialog1 = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.addDialog1,
            callback: function($$v) {
              _vm.addDialog1 = $$v
            },
            expression: "addDialog1"
          }
        },
        [
          _c("div", { staticClass: "viewBox" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.reportInfo.title))
            ]),
            _c("div", { staticClass: "remark" }, [
              _vm._v(_vm._s(_vm.reportInfo.remark))
            ])
          ]),
          _c(
            "div",
            { staticClass: "flexBox" },
            [
              _vm._v("上传的报告:\n          "),
              _vm._l(_vm.reportInfo.files, function(item, index) {
                return _c("div", { key: index, staticClass: "flex" }, [
                  _vm._v(
                    "\n              报告" +
                      _vm._s(index + 1) +
                      "\n              "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.preview(item)
                        }
                      }
                    },
                    [_vm._v("预览")]
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.download(item)
                        }
                      }
                    },
                    [_vm._v("下载")]
                  )
                ])
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }