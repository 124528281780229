var render, staticRenderFns
import script from "./Custom.vue?vue&type=script&lang=js&"
export * from "./Custom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1906f871",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1906f871')) {
      api.createRecord('1906f871', component.options)
    } else {
      api.reload('1906f871', component.options)
    }
    
  }
}
component.options.__file = "src/views/account/settings/Custom.vue"
export default component.exports