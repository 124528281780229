var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-modal", {
    attrs: {
      title: _vm.title,
      width: _vm.width,
      placement: "right",
      closable: false,
      destroyOnClose: "",
      visible: _vm.visible
    },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "footer",
        fn: function() {
          return [
            _c("a-button", { key: "back", on: { click: _vm.close } }, [
              _vm._v("关闭")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }