var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index" }, [
      _c("div", { staticClass: "banner" }, [
        _c("div", { staticClass: "txt" }, [_vm._v("隐私条款")])
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "p",
          {
            staticStyle: {
              "text-align": "center",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  "line-height": "40px",
                  color: "rgb(51, 51, 51)",
                  "font-size": "20px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("《")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  "line-height": "40px",
                  color: "rgb(51, 51, 51)",
                  "font-size": "20px"
                }
              },
              [_vm._v("毅力实验通")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  "line-height": "40px",
                  color: "rgb(51, 51, 51)",
                  "font-size": "20px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("》隐私条款")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "text-align": "right",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("更新日期：")
                ]),
                _vm._v("2023"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("年")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("8")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("月")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("11")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("日")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "text-align": "right",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("生效日期：")
                ]),
                _vm._v("2023"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("年")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("8")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("月")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("11")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("日")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("感谢您使用毅力实验通产品及相关服务！")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "text-indent": "32px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息事关个人安全和隐私保护，我们一向注重保护使用")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("毅力实验通")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（或简称")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("本产品")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）产品和服务的用户（以下统称")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("用户")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("或")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("您")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）个人信息安全及隐私安全。 您在使用本产品及服务时，可能会因为实际业务需要而收集和使用您的一些个人信息（或简称"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）。我们希望通过本隐私条款向您清晰地介绍本产品对您个人信息的处理方式 （包括但不限于个人信息的收集、使用、保护），请您仔细阅读，在需要时，根据本隐私条款的指引做出您认为适当的选择。如果您未满"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("18")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "周岁，请在法定监护人陪同下仔细阅读并充分理解本协议， 并征得法定监护人的同意后使用本产品及相关服务。如果您继续使用本产品及服务，表示您已经充分阅读和理解我们协议的全部内容。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第一条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("序言说明")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("毅力实验通由保北京毅力澎湃技术有限公司联合（以下简称")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我公司")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("或")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我们")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）开发运营，《毅力实验通隐私条款》（以下简称")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("本政策")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）适用于")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("毅力实验通")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("所有产品和服务 （包括")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("毅力实验通")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("平台及所有客户端应用以及所有的发布版本）。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "按照《信息安全技术个人信息安全规范》的规定，本政策所指"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。 本隐私条款中涉及的个人信息涉及自然人的基本资料（包括姓名、性别、工（学）号、手机号码、电子邮箱、"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("QQ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "号、个人图标）、网络身份标识信息（包括工（学）号、手机号码以及个人登录密码）、 个人上网记录（使用本产品及服务过程中的浏览记录、使用记录、点击记录）、个人常用设备信息（计算机及移动设备的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("IP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "地址及操作系统类型）。我们实际具体收集的个人信息种类以下文描述为准。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "本产品为面向职业院校（包含中职、高职、应用型本科、职业技术大学）的网络化教学"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("实践管理")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("而开发的线上、线下混合式实践教学")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("实践")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "管理平台，通常以学校内网的私有云部署为主，主要应用于校内师生的实践教学、"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "管理和服务之用。此外，本产品还提供用于产品演示、教学体验、账号试用的公有云服务平台（"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin.detasci")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(".com"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "），无论采用公有云部署还是采用私有云部署，由于属于特定的职业院校内部应用产品， 都采用内部账号注册管理的方式，通常不对外公开注册账号，不属于开放性的互联网产品。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.4 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "按照《中华人民共和国个人信息保护法》之规定，在个人信息收集的过程中要明确告知"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者及其联系方式")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "，因此本产品的账号注册过程中， 无论是采用扫描二维码的个人用户账号注册还是采用"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("文件的单位用户集中账号注册，在相关页面都会显示")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的姓名和电话，")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "是个人信息处理过程中的第一责任人， 您遇到涉及个人信息安全保护的相关问题可以咨询"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("或者寻求相应的帮助。本隐私条款将详细告知包括但不限于")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "个人信息的处理目的、处理方式，处理的个人信息种类、保存期限"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("， 用户在详细阅读上述信息后决定是否提供上述信息。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.5 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("为了保护用户个人信息的安全，本产品支持")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("包括但不限于")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "手机号，工（学）号，混合式登录三种账号认证方式进行本产品的登录验证，其中工（学）号属于去标识化的用户登录方式，"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "私有云部署的客户学校可以根据自己的需求选择采用哪种账号验证方式。为了保障个人信息的合法性和可溯源，将手机号作为账号开通实名制认证的一种辅助手段，用之实现用户账号的密码修改和密码找回之用。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("1.6 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "由于本产品收集的个人信息和产品的使用息息相关，如用户不提供上述信息将无法使用该平台的部分或全部功能，因此当您登录使用本产品的过程中同意并勾选《用户协议》和《隐私条款》表示您接受本隐私条款的全部内容， 并我们按本隐私条款收集、使用和保护您的相关个人信息。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第二条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("个人信息收集")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "您理解并同意本产品为了向您提供更优质的服务，我们将依据相关法律规定并根据根据合法、正当、必要的原则，收集和使用您在使用服务过程中主动提供或由于产品和服务需要而产生的个人信息。 我们承诺绝不会收集任何与本产品功能业务无关的个人信息或非必要信息。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "我们收集您的个人信息类型主要包括两类：第一种为您务必授权我们收集的个人信息，这些信息将是我们产品与服务的核心业务功能所必需的信息，此类信息是产品与服务正常运行的基础， 如您拒绝提供将无法正常使用我们的产品与服务。该类信息包括自然人的基本资料（包括姓名、性别、工（学）号、手机号码）、网络身份标识信息（包括工（学）号、手机号码以及个人登录密码）、 个人上网记录（使用本产品及服务过程中的浏览记录、使用记录、点击记录）、个人常用设备信息（计算机及移动设备的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("IP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "地址及操作系统类型）；第二种为您可以根据自己的选择授权我们采集的个人信息， 此信息为附加功能而非核心业务功能所需的信息，如您拒绝提供可能会导致部分功能不能使用，但不影响您对核心功能业务的正常使用。这些个人信息包括个人基本资料中的电子邮箱、"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("QQ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("号、个人图标， 其中电子邮箱和")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("QQ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("号用于个人联系，个人图标用于用户个性化显示。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "由于本产品以职业院校的内网部署和内部使用为主，不采用互联网公开注册方式进行用户账号注册，使用本产品及服务必须通过以下方面注册账号后才能成为真正用户。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）院校管理员用户注册：私有云部署的本产品管理员账号为内置账号（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("），")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "账号全面负责本产品的一切管理性功能，是平台安全和个人信息的安全的首要负责人。"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("账号的用户由客户院校自行指定， ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "账号第一次登录过程中需要绑定手机号码，正常登录后可修改个人信息，该账号享有本平台管理的一切权利，也是本学校默认的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "（单位集中用户的集中添加情况）， 个人用户在本产品使用过程中涉及个人信息的相关问题可以联系"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("进行咨询或寻求帮助。（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）个人用户注册：本产品支持教师和学生账号的个人用户注册方式。其中学生账号的个人注册可以通过上述院校管理员（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）或其他管理员共享的二维码进行个人注册， 在注册界面会提示相关的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的姓名和联系方式，在该")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "进行账号审核通过后才能正常登录和使用本产品。此外，学生账号还可以通过授课教师创建课堂后共享的二维码进行扫码注册账号， 但该种注册方式该教师属于"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "，在注册界面会提示其姓名和联系方式，在该教师进行账号审核通过后才能正常登录和使用本平台。 教师账号的个人注册只能通过院校管理员（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）或其他管理员共享的二维码进行个人注册，在注册界面会提示相关的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的姓名和联系方式，在该")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "审核通过后才能正常登录和使用本平台。 个人账号在确定不使用本产品提供的服务后可自己注销账号，也可以联系院校管理员（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）进行注销账号。涉及")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2.2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "条款的第二种类型的个人信息，可根据个人需要登录本产品后进行添加和完善。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）单位用户注册：为了便于集中的或者大批量的用户账号注册，本产品支持基于"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "文件模板形式进行集中的个人信息收集和账号注册工作， 该种账号注册方式可由院校管理员（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("admin")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）或其他管理员统一下载提供账号注册的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("模板文件，该")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("模板文件上会提示")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的姓名及联系方式。 在使用")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("模板文件收集个人信息的过程中一切个人数据安全均由")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "负责，除了上传本产品之外，切不可留做他用，另外在工作完成后应该进行"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("文件的本地销毁操作， 由")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "或任何中间环节造成的个人信息泄露以及为此产生的一切后果本公司均不承担任何法律责任。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）毅力实验通公有云平台（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("www.gongdanketang.com")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）的账号注册：毅力实验通公有云平台的账号由体验产品的任课教师在其建立的课堂中采用"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Excel")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "模板文件集中采集注册或者发布的二维码通过个人扫描注册， 该任课教师作为该平台的"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "应遵照《中华人民共和国个人信息保护法》之规定，享有本课堂所有人员个人信息的处置权利和承担个人信息的保护责任， 在没有获得本课堂用户或平台中其他用户的授权不得将个人信息共享或用做他用。此外，在产品体验（试用）结束或出于任何原因之后不在使用本产品的公有云平台， 请该"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("联系本课堂的所有用户进行个人账户的注销操作或由")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息处理者")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "在告知本课堂所有用户的情况下主动进行全部账号个人信息的删除操作，以最大化保护个人信息的安全性。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.4 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "为使您获得产品的最优用户体验，本产品也推出了移动应用端程序（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("），结合")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("程序的固有使用特性，本产品的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "端以下附加功能可能会收集和使用您的个人信息。如果您不提供这些个人信息， 您依然可以使本产品，但您可能无法正常使用部分功能。这些附加功能包括如下。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）相机、摄像头的访问服务：当您使用本产品的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "进行上传视频、上传照片、记录操作过程、更换个人图标、扫码注册账号、工单识别等功能时，您需要授权开启相机"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("摄像头权限后才能使用相机")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "摄像头的拍照上传功能， 如果您限制相机和摄像头的使用，将影响上述功能的正常使用。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）相册的图片")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("视频访问及上传的服务：当您使用本产品的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("进行更换个人图标、上传作业图片及实训截图、发布图片")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("视频类资源、在活动中提交图片")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("视频类附件、私聊发送图片")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "视频类消息以及其他的需要上传图片的应用时， 您需要授权开启相册的图片"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "视频访问和上传权限后才能使用该功能上传您的手机图片或视频，如果您限制相册的图片"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("视频访问及上传功能将影响上述功能的使用。（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）麦克风语音技术的服务：当您使用本产品的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "录制和上传语音资源或文件时，需要您授权开启麦克风的权限，该功能主要应用于工单中语音类资源的上传、语音类实训过程的记录、语录类回答问题、语音类活动参与、 讨论过程或提问过程的语音交互等功能。您需要授权开启麦克风语音技术权限后才能使用该功能，如果您限制麦克风语音技术服务将影响上述功能的使用。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）文件存储相关的图片、视频、本地文件访问及上传（相册")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("存储卡读取权限）服务：当您使用本产品的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "进行工单开发、课程建设、做工单、做作业、进行考试、参加课外活动等过程中涉及到图片、视频、 文件资源的上传和资源本地存储功能。您需要授权开启相册"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("存储卡读取权限后才能使用该功能，如果您限制相册")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("存储卡读取权限将影响上述功能的使用。（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("5")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）本产品")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("不采集地理位置信息（如通过")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("GPS")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("、蓝牙或")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("WIFI")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("信号获得的位置信息）、客户")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("IP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "地址、查看手机通讯录、监听手机通话状态、设备标识符等敏感手机操作权限。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.5 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "以下情形中，我们收集、使用您的个人信息不必征得您的授权同意"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v(":")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）与国家安全、国防安全有关的；（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）与公共安全、公共卫生、重大公共利益有关的；（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）与犯罪侦查、起诉、审判和判决执行等有关的；（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("5")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）所收集的个人信息是个人信息主体自行向社会公众公开的；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("6")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("7")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）根据您的要求签订和履行合同所必需的；（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("8")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）用于维护所提供的产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("9")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("10")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）法律法规规定的其他情形。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("2.6 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "为确保本产品的正常运转和为用户提供简单快捷且丰富的网络体验，我们会采用"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("技术收集和记录部分个人信息（在用户本地计算机）。")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("通常包含会话")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("ID")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "、用户登录的相关信息，数据信息都采用匿名的方式， 以便您能登录或使用依赖于 "
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("技术进行快速登录。借助于")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "能帮助我们确定用户连接的页面和内容，用户在本产品上花费的时间和用户所选择的服务。我们不会将"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "用于本政策所述目的之外的任何用途。 您有权选择接受或拒绝接受 "
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("。您可以通过修改浏览器设置的方式拒绝接受 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("。但如果您选择拒绝接受 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("，您可能无法登录或使用依赖于 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Cookie ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的本产品的网络服务或功能。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第三条个人信息使用")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("3.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "在收集您的个人信息后，我们本着最小范围收集的原则，争取做到用户即可获得产品的完美体验又可最大化保障个人信息安全。在本产品的实际应用过程中，针对不同类型的个人信息、不同的业务模块、 不同的业务需求，我们将采取不同的个人信息使用和处理方式。本产品及服务的个人信息使用总体情况如下表所示："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "table",
          {
            attrs: {
              border: "1px",
              align: "center",
              cellpadding: "0",
              cellspacing: "1"
            }
          },
          [
            _c("tbody", [
              _c("tr", { staticClass: "firstRow" }, [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-width": "1px",
                      "border-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人信息名称")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("类型")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("首页")]
                            ),
                            _vm._v("&"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("数据分析")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("工单开发")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("资源库建设")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课堂教学")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动组织")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("现代学徒制")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("职业发展")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级管理")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("专业诊改")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教务")]
                            ),
                            _vm._v("&"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("专家评审账号")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top-width": "1px",
                      "border-top-color": "rgb(220, 220, 220)",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("系统设置")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("手机号")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "登录用户显示，用户个人信息修改，密码修改，个人账号注销。优秀个人成果展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班主任教师需要掌握的学生手机联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("账号注册，账号登录，密码修改")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("工（学）号")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "教师，学生身份确认，学生管理，课堂教学应用展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "教师，学生身份确认，学生管理，活动应用展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "教师，学生身份确认，学生管理，岗位实习应用展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("身份确认，学徒管理，应用展示，")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教师，学生身份确认，学生管理，应用展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "班主任教师，学生身份确认，学生管理班级应用展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "专业成员的身份确认，专业管理相关的应用及数据展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "所有成员的去标识化展示（专家评审），个人身份确认"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("账号注册，账号登录，去标识化处理")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("姓名")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("记录工单开发者姓名")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("记录课程开发者姓名")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教师，学生管理，课堂教学应用及展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教师，学生管理，活动组织业务应用及展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教师，学生管理，岗位实习业务应用及展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "学徒制日常业务管理，学徒制业务应用及展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("教师，学生职业发展业务管理，应用及展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "教师，学生班级管理业务相关的管理、应用及展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("专业管理业务相关的管理、应用及展示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [
                                _vm._v(
                                  "所有成员的业务管理和数据展示，涉及专家评审进行匿名化展示"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("账号注册，个人检索，权限分配")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("性别")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标差异化显示")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _vm._v("QQ"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("号")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("记录课程开发者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("辅讲教师联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学徒制创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("职业岗位创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级创建者的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("专业实体创建者的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("上课教师的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("账号注册")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("电子邮箱")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("记录工单开发者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("记录课程开发者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("辅讲教师联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学徒制创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("职业岗位创建者联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级创建者的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("专业实体创建者的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("上课教师的联系方式")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("账号注册")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("登录密码")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("登录密码初始化")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("操作系统类型")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("必要")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("客户端类型判断")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标修改")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学生个性化图标显示（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                })
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("相机、摄像头（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标修改（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("工单开发相关的图片或视频资料上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课程开发相关的图片或视频资料上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课堂教学相关的图片或视频上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动组织相关的图片或视频上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习相关的图片或视频上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学徒制相关的图片或视频上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级管理相关的图片或视频上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                })
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("麦克风语音（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("工单开发相关的语言资料上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课程开发相关的语言资料上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课堂教学相关的语音上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动组织相关的语音上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习相关的语音上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学徒制相关的语音上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级管理相关的语音上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                })
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left-width": "1px",
                      "border-left-color": "rgb(220, 220, 220)",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("相册、卡")]
                            ),
                            _vm._v("(APP)")
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("可选")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("个人图标修改（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("工单开发相关的图片、文件上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课程开发相关的图片、文件上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("课堂教学相关的文件、图片上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("活动组织相关的文件、图片上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("岗位实习相关的文件、图片上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("学徒制相关的文件、图片上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c(
                  "td",
                  {
                    staticStyle: {
                      padding: "5px",
                      "border-left": "none",
                      "border-right-width": "1px",
                      "border-right-color": "rgb(220, 220, 220)",
                      "border-top": "none",
                      "border-bottom-width": "1px",
                      "border-bottom-color": "rgb(220, 220, 220)",
                      background: "rgb(255, 255, 255)"
                    },
                    attrs: { valign: "center" }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-family": "等线",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "Raleway",
                              color: "rgb(51, 51, 51)",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("班级管理相关的文件、图片上传（")]
                            ),
                            _vm._v("APP"),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "宋体" } },
                              [_vm._v("）")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                }),
                _c("td", {
                  staticStyle: {
                    padding: "5px",
                    "border-left": "none",
                    "border-right-width": "1px",
                    "border-right-color": "rgb(220, 220, 220)",
                    "border-top": "none",
                    "border-bottom-width": "1px",
                    "border-bottom-color": "rgb(220, 220, 220)",
                    background: "rgb(255, 255, 255)"
                  },
                  attrs: { valign: "center" }
                })
              ])
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("注：上述表空白项为不涉及该项个人信息。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("3.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "个人信息除上述在本产品的基本应用外，还可能存在如下的额外应用，具体说明如下："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）根据教育行政部门或研究机构要求，收集或推送的用以评估资源应用和在线教学、管理绩效的相关应用数据信息；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）根据本院校教务部门的要求，和学院（校）的智慧校园、")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("MIS")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "平台、诊改平台进行数据对接，推送的相关教师或学生的教学、管理、服务有关的应用数据信息；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）为保障您获得更好的产品体验而进行的内容推送，或在您允许的情况下，向您推荐您可能感兴趣或需要的内容；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）我们处于个人身份验证、客户服务、安全防范、诈骗监测以及存档和备份用途，确保我们向您提供的产品和服务的安全性；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("5")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）为改进我们的产品与服务所必须的功能以及邀请您参与我们服务有关的用户调研；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("6")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）根据法律法规或监管要求向相关部门提供的报告；")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第四条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("个人信息共享、转让及公开披露")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("4.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("共享")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）原则上，我们不会与任何第三方公司、组织和个人共享您的个人信息。但以下情况除外："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("①")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("事先获得您明确的同意或授权；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("②")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "根据相关的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("③")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "根据本学院（校）教学主管部门出于教学管理目的的正常要求而进行的个人信息共享，或者和本学院（校）的智慧校园、"
                  )
                ]),
                _vm._v("MIS"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "系统、教学诊改等信息化管理平台进行的程序对接和数据共享， 但仅限于必要数据和部分个人信息的共享；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("④")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "在法律法规允许的范围内，为维护您或其他用户或社会公众利益、财产或安全免遭损害而有必要提供；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("⑤")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "只有共享您的信息才能实现我们的产品或服务的核心功能为您提供所需的服务；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("⑥")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("符合与您签署的相关协议或其他的法律文件约定；")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("2"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）对本产品与之共享个人信息的任何第三方公司、组织和个人，我们会要求其遵守保密约定，要求他们按照本产品的说明、本隐私条款以及其他相关保密和安全措施来处理您的个人信息。 我们郑重承诺本公司及本产品不会违反本隐私条款中所作的承诺而以营利目的出售、出租、共享或以其它方式披露您的个人信息。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("3"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）互联网并非绝对安全的环境，我们建议您在使用本产品进行直接的信息共享或主动与第三方交流时注意保护您的个人信息安全。您在使用本产品及服务时所提供、上传或发布的内容和信息， 都可能被第三方获取或泄露。当您因您自发的共享行为导致的信息泄漏以及导致的严重情况时，我们对此不承担相关法律责任。您需要谨慎地考虑是否在使用本产品和服务时向第三方披露您的个人信息。 您主动共享或披露个人信息的情况包括但不限于下列情况："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("①")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "当任何人或公司在本产品中向您或您的家长发起调查或类似的数据获得要求并由您向其提供的个人信息（包括但不限于身份证信息、家庭住址、银行卡号、父母工作情况类似敏感信息）时，"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "本产品不会因此获取您向任何人提供的任何信息，但鉴于该过程会因此获得您的个人信息，故我们再此特别提醒您，在填写相关资料时请特别注意保护您的个人信息安全并对调查发起方进行详细的可靠性分析。"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("②")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "当您将本产品的下载链接、邀请链接、笔记链接、话题链接以及课程活动分享至微信好友，微信朋友圈，"
                  )
                ]),
                _vm._v("QQ"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("好友和")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("QQ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "空间时以及其他任何第三方平台，可能会涉及到您或他人的个人敏感信息（包括个人图标、姓名等）， 请您注意确认第三方平台的隐私条款以保护您的个人信息安全。若您分享的信息中涉及第三方个人隐私信息的，请确认已获得第三方授权。"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("③")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("任课教师在教学过程中通过")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("一键开放")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("或")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("开放课")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "功能进行开放式教学或参加开放课评审的过程中，将会生成特殊的共享连接允许外部人员查看本课堂的教师和学生的部分信息， 尽管我们已经采用了大量的匿名化和去标识化技术防范个人信息泄露，但不排除其他人员采用特殊手段推导出本课堂涉及的关键数据和个人信息，因此您需要谨慎决定是否使用该功能， 如您使用该功能将意味着您以充分知晓该功能的开放后果并对您以及本课堂的所有参与人的个人信息负责。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("4.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("转让")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）原则上，我们不会与任何第三方公司、组织和个人转让您的个人信息。但以下情况除外："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("①")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("事先获得您明确的同意或授权；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("②")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("③")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "在涉及公司合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的主体公司、组织继续受本隐私条款的约束。否则，我们将要求该公司、组织重新向您征求授权同意。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("4.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("公开披露")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "如有公开披露的需要，我们仅会在以下情形且符合业界标准的安全防护措施的前提下公开披露您的个人信息："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）先获得您同意或授权本产品披露的；（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）用户在开放课建设过程中自己公开的课程或为了参加各种竞赛评审自主进行公开披露的个人信息，以及用户通过本产品自行向社会公众公开披露个人信息的任何行为；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "） 根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意："
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("①")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("相关法律法规规定的、强制性的行政执法或司法要求的；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("②")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("与国家安全、国防安全直接相关的；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("③")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("与公共安全、公共卫生、重大公共利益直接相关的；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("④")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("与刑事侦查、起诉、审判和判决执行等直接相关的；")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("⑤")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("⑥")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "从合法公开披露的信息中收集您的个人信息的，如合法的新闻报道、政府信息公开等渠道。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第五条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("个人信息存储")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("5.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "采用私有云部署的本产品个人信息在您所在学校的内网进行存储。本产品的公有云平台的个人信息在运营商的网络上进行存储。无论何种情况您的个人信息将全部被存储于中华人民共和国境内。 目前我们不存在跨境存储您的个人信息或向境外提供个人信息的场景。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("5.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "本产品仅在实现服务目的所需的最短时限内保留您的个人信息，您使用我们的产品服务的最短保留时间由产品的服务类型决定。对于各个院校的私有云用户， 具体的账号最短保留时间由各个院校的自主政策决定（如有的院校要求学生毕业后出于个人信息保护的目的进行个人信息清理）。如果客户院校没有相关的具体管理规定， 我们将根据业务需要在法律许可的最长时间范围内持续为您保存个人信息，除非您自主进行账号注销。对于毅力实验通公有云平台，由于该平台用于教学体验和试用目的，出于个人信息保护的目的， 我们会定期进行个人信息清理，通常在个人信息清理之前我们会提前进行通知。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("5.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "如果您确定之后不再使用本产品以及希望本产品中不再保存您的个人信息，请自行注销账号或者联系平台的管理人员帮助注销账号，我们将按照法律规定删除您的个人信息或者在法律许可的情况下作匿名化处理。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("5.4 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "无论是公有云还是院校私有云，当我们的产品或服务发生永久性停止运营的情形时，我们将停止继续收集个人信息，同时我们将采取推送通知的形式告知您，并在合理的期限内删除、 匿名化或进行其他使之无法识别出用户个人身份信息特征的技术性措施来处理您的个人信息。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第六条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("个人信息保护")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("6.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我们")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⾮")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(
                  "常重视产品安全以及个人信息安全的保护工作，在产品研发和数据保护方面，涉及个人信息的数据收集、存储、显示、处理、使"
                )
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽤")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("、销毁等各个环节")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("建")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽴")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(
                  "了全面的防护机制，并根据信息敏感程度和不用的应用需求采取不同的控制措施，"
                )
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(" "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "包括但不限于：身份鉴别（即当您首次注册时通过您的手机号码唯一确定您的身份信息）、数据加密措施（"
                  )
                ]),
                _vm._v("Md5"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "加密保护，不可逆的加密算法）、访问控制（您须通过密码或者手机验证码进行登录，否则无法正常使用本产品及服务）、 恶意代码防范（采用严格的操作系统权限控制和防火墙技术，防范恶意代码执行）、安全审计（通过审计过滤技术防止"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("SQL")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("注入的发生）。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("6.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "我们特别注重公司的人员管理和平台运营的保障制度建设，在日常工作当中我们会与全部员工签署保密协议并定期进行相关的信息安全培训工作，在实际工作过程中我们采用专人专岗负责制度，对可能接触到个人信息的人员进行严格管理和监控， 对于数据访问、内外部传输使"
                  )
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽤")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("、脱敏、解密等重要操作建")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽴")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("了一套完整的工作审批和安全审计策略。")]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("6.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("尽管从管理和技术方面我们都采取了各种安全防范措施尽")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⼒")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(
                  "做到使您的个人信息和数据不被泄露、损毁或丢失，但互联网中不存在绝对安全的网络。您需理解并接受，您接"
                )
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⼊")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("我们的服务所")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽤")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("的系统和通讯")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽹")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("络，")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(" "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("有可能因我们可控范围外的因素")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽽")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(
                  "出现问题导致个人信息泄露或者数据丢失，请您及时保存或备份您的"
                )
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽂")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("字、图")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽚")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("以及其他信息。")]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("6.4 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "我们的产品有一系列的账号安全保护措施，但很多的账号密码泄露都是由用户自身引起的，因此请您务必妥善保管您的账号及密码信息，设置较为复杂的密码，不在公共场合电脑使用"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("记住密码")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("功能， 切勿将密码告知他")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⼈")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("（我们的客服人员绝不会询问您的密码），如果您发现")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⾃⼰")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("的个")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⼈")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("信息泄露，特别是您的账号和密码发")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽣")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("泄露，请您")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "微软雅黑",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("⽴")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [_vm._v("即与我们的客服或平台的管理人员联系，")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v(" "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "以便第一时间采取相应的措施保护个人数据。对于用户由于自身原因引发的个人信息泄露或者其他问题我们不承担任何责任。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("6.5 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "如不幸发生个人信息安全事件或网络安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、用户可自主防范和降低风险的建议、 对您的个人信息的补救措施等。我们将及时将事件相关情况以通知、邮件或电话的方式告知，同时我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第七条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("个人信息管理")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "text-indent": "32px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "在使用本产品及服务的过程中，您有权对于您个人信息进行访问、更正、删除、注销和权利撤回等操作，以使您拥有充分的能力保障您的隐私和安全。您的权利及相关操作说明如下："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("7.1 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息的访问和更正")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "您有权按照下列说明访问和更正您的个人信息，法律法规规定的例外情况除外。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）基本信息：您可以通过登录 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Web ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("版毅力实验通首页，点击右上角导航栏")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("菜单 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("|“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("修改个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("栏目访问或修改您的个人信息，可以直接上传图片修改")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人图标")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("。 您也可以登录毅力实验通 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("App ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("进入")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("栏目后点击个人图标后选择")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("修改个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("，查看和修改个人信息，包括修改")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人图标")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("。个人信息的修改包括：工（学）号、姓名、性别、")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("QQ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "号、 电子邮箱、备注、个人图标。其中手机号作为唯一登录标识只能由本产品的平台管理员进行更改或者使用新的手机号重新进行账号注册。"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("教师")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("学生")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("身份由于涉及管理权限职责不允许修改。此外， ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("院系")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("企业、专业")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("/")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("部门、班级、入学年份")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "由于涉及院校的具体组织架构和权限划分不允许用户自行修改，如发生调整可联系本产品的平台管理员进行修改。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）密码信息：您可以通过登录 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Web ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("版毅力实验通首页，点击右上角导航栏")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("菜单 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("| “")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("修改密码")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "修改您的产品登录密码， 对于开启手机短信验证的情况需要通过手机短信验证才允许修改密码。您也可以登录毅力实验通 "
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("App ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("进入")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("栏目后点击个人图标后选择")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("修改密码")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("进行产品登录密码修改。（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）隐私设置：您也可以通过手机设置功能关闭上述手机")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的访问权限，比如可以通过 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("iOS ")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("设备中的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("设置")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("|")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("隐私")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("|")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("照片")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("来关闭您对手机相册的授权， 或者在重新安装")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("APP")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("的过程中选择")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("禁止")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("使用上述访问权限进行隐私修改。（")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）您有权撤回您对个人信息的授权。也可以通过注销账号的方式永久撤回我们收集您个人信息的全部授权。您需理解，当您撤回授权后，我们无法继续为您提供撤回授权所对应的特定功能和服务。 但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("7.2 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息注销")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "） 请注意，注销账号是不可恢复的操作。在注销账号后，毅力实验通将停止为您提供产品及（或）服务，您在毅力实验通上的个人信息将进行匿名化处理，所有数据将被全部永久破坏或删除，无法找回。 个人注销账号操作，采用"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("Web")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("方式登录首页后，在页面右上角导航栏")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("菜单 ")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("| “")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("注销账号")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "后弹出警告信息，同意后再次进行密码验证就可以进行账号注销操作。手机"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("App")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("端进入")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("我的")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("栏目后点击个人图标， 选择")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("“")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("注销账号")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("”")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "后弹出警告信息，同意后再次进行密码验证就可以进行账号注销操作。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _vm._v("7.3 "),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("个人信息删除")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）为了保障个人信息安全及个人言论的合法性，您如果涉及侵权或者涉及发表不法言论，我们有权删除您的个人信息以及您上传或者发布的任何不良言论或者侵权信息。并保留应用法律手段追责的权利。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）如果本产品停止运营或停止提供相关服务，我们将会及时停止收集您个人信息，并将停止运营的通知以逐一送达或公告的形式通知您，之后我们会根据相关规定对所持有的个人信息进行删除或匿名化处理。（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）本产品的私有云客户院校出于本院校管理需要或者本产品的公有云平台规定的客户院校试用期结束（包含公有云平台不再为您提供本产品的相关服务。），将由管理员主动进行的账号以及个人信息删除或者个人数据删除， 上述删除操作前将会提前发布通知进行通告。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第八条、未成年和敏感个人信息保护")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "text-indent": "32px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "根据《中华人民共和国个人信息保护法》的相关规定：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账号、"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。我们郑重声明：我们的产品从未涉及上述规定的个人敏感信息收集操作，如果客户院校或任何人通过我本产品的一些特定功能进行上述信息收集，您有权拒绝，"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "如果您主动进行个人敏感信息的共享和发布所产生的一切责任与我们无关。此外我们的产品及服务定位为职业院校，按照中职院校的最初起点学生年龄控制通常在"
                  )
                ]),
                _vm._v("15"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("岁以上，因此不涉及")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("14")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("岁以下未成年人的个人信息采集， 如有特殊情况存在")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("14")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "岁以下未成年使用本产品，务必由监护人决定是否提供上述信息）。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第九条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("隐私条款的变更")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "text-indent": "32px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "随着国家政策法规的调整已经公司业务、产品特性的调整，本隐私条款也可能进行相应变更。当本政策发生重大变化时我们将会在第一时间采取公告的方式进行通知，构成重大变化的情况包括但不限于："
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(102, 102, 102)",
                  "font-size": "14px",
                  background: "rgb(255, 255, 255)"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("（")
                ]),
                _vm._v("1"),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）我们的产品及服务涉及的服务范围、服务模式发生重大变化。如收集、使用个人信息的目的、处理个人信息的类型、个人信息的使用方式和使用范围、使用期限发生改变；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("2")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）本公司的经营主体、组织架构、所有权结构发生重大变化。如业务调整、兼并收购重组引起的所有者变更；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("3")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）通过权威部门评审，个人信息风险评估报告中出现高风险；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("4")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）软件功能调整，您参与个人信息处理方面的权利及其行使方式发生重大变化；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("5")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "）涉及个人信息共享、转让或公开披露的方式、主要对象等发生变化；（"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("6")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("）其他可能对您的个人信息权益产生重大影响的变化。")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "line-height": "28px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Raleway",
                    "line-height": "32px",
                    color: "rgb(51, 51, 51)",
                    "font-size": "16px"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("第十条")
                  ]),
                  _c("span", { staticStyle: { "font-family": "宋体" } }, [
                    _vm._v("联系我们")
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              "text-indent": "32px",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "您在使用本产品及服务的过程中，如果对本《隐私条款》有任何的疑义或建议，或您认为您的个人信息没有得到本《隐私条款》规定的保护，您可以通过以下方式联系我们，我们将真诚地处理您的投诉及建议。"
                  )
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v(
                    "一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。"
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("客服电话：")
                ]),
                _vm._v("13426463545")
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              margin: "20px 0 5px",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap",
              background: "rgb(255, 255, 255)"
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "Raleway",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("公司地址：")
                ])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "宋体",
                  color: "rgb(51, 51, 51)",
                  "font-size": "14px"
                }
              },
              [
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("北京市海淀区知春路")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("7")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("号北航致真大厦")
                ]),
                _c("span", { staticStyle: { "font-family": "Raleway" } }, [
                  _vm._v("A")
                ]),
                _c("span", { staticStyle: { "font-family": "宋体" } }, [
                  _vm._v("座四层")
                ])
              ]
            )
          ]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "text-align": "justify",
              "font-family": "等线",
              "font-size": "14px",
              "text-wrap": "wrap"
            }
          },
          [_c("span", { staticStyle: { "font-size": "14px" } }, [_vm._v(" ")])]
        ),
        _c("p", [_c("br")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }