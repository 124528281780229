var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "普通列表" } },
            [_c("default-table")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "只读列表" } },
            [_c("read-only-table")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "三级联动" } },
            [_c("three-linkage")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }