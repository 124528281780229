var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { "max-width": "500px", margin: "40px auto 0" } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: "付款账户",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
              }
            },
            [
              _c(
                "a-select",
                { attrs: { value: "1", placeholder: "ant-design@alipay.com" } },
                [
                  _c("a-select-option", { attrs: { value: "1" } }, [
                    _vm._v("ant-design@alipay.com")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "收款账户",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
              }
            },
            [
              _c(
                "a-input-group",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "middle"
                  },
                  attrs: { compact: true }
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { defaultValue: "alipay" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "alipay" } }, [
                        _vm._v("支付宝")
                      ]),
                      _c("a-select-option", { attrs: { value: "wexinpay" } }, [
                        _vm._v("微信")
                      ])
                    ],
                    1
                  ),
                  _c("a-input", {
                    style: { width: "calc(100% - 100px)" },
                    attrs: { value: "test@example.com" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "收款人姓名",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
              }
            },
            [_c("a-input", { attrs: { value: "Alex" } })],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "转账金额",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
              }
            },
            [_c("a-input", { attrs: { prefix: "￥", value: "5000" } })],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 19, offset: 5 } } },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.nextStep } },
                [_vm._v("下一步")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }