var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("p", { staticClass: "font1" }, [_vm._v("让我们的实验实践教学")]),
            _c("p", { staticClass: "font2" }, [_vm._v("焕发新的活力")]),
            _c(
              "a-button",
              {
                staticClass: "demonstrate-btn",
                attrs: { type: "primary" },
                on: { click: _vm.yuyue }
              },
              [
                _c("img", {
                  staticClass: "demonstrate",
                  attrs: {
                    src: require("../../../assets/newIndex/demonstrate.png"),
                    alt: "logo"
                  }
                }),
                _vm._v("预约演示")
              ]
            )
          ],
          1
        ),
        _c("img", {
          staticClass: "right",
          attrs: {
            src: require("../../../assets/newIndex/banner-right.png"),
            alt: "logo"
          }
        })
      ])
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "scrollBox" },
      [
        _c(
          "vue-seamless-scroll",
          {
            staticClass: "seamless-warp2",
            attrs: { data: _vm.imagesList, "class-option": _vm.optionLeft }
          },
          [
            _c("ul", { staticClass: "index_footer" }, [
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/hbkj.png"),
                    width: "230",
                    height: "60",
                    alt: ""
                  }
                })
              ]),
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/bh.png"),
                    alt: ""
                  }
                })
              ]),
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/hbdx.png"),
                    alt: ""
                  }
                })
              ]),
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/nk.png"),
                    alt: ""
                  }
                })
              ]),
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/bh.png"),
                    alt: ""
                  }
                })
              ]),
              _c("li", { staticClass: "footer_menu" }, [
                _c("img", {
                  staticClass: "footer_banner",
                  attrs: {
                    src: require("../../../assets/newIndex/jlxy.png"),
                    alt: ""
                  }
                })
              ])
            ])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("a-carousel", { attrs: { autoplay: "" } }, [
          _c("div", { staticClass: "carouselBox" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "left_header" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("\n                Anytime"),
                    _c("br"),
                    _vm._v("\n                Anywhere\n              ")
                  ]),
                  _c("img", {
                    staticClass: "header_line",
                    attrs: {
                      src: require("../../../assets/newIndex/line.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("p", { staticClass: "text2" }, [
                  _vm._v("每一个学生随时随地能看到那些设备能借用")
                ]),
                _c("p", { staticClass: "text3" }, [
                  _vm._v("不会让学习的热情因为等待太久而浇灭")
                ])
              ]),
              _c("img", {
                staticClass: "carousel_mobile",
                attrs: {
                  src: require("../../../assets/newIndex/mobile3.png"),
                  alt: ""
                }
              })
            ])
          ]),
          _c("div", { staticClass: "carouselBox" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "left_header" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("\n                Everyday"),
                    _c("br"),
                    _vm._v("\n                Review\n              ")
                  ]),
                  _c("img", {
                    staticClass: "header_line",
                    attrs: {
                      src: require("../../../assets/newIndex/line.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("p", { staticClass: "text2" }, [
                  _vm._v("记录我们实验实践教学的每一步变化与成长")
                ]),
                _c("p", { staticClass: "text3" }, [
                  _vm._v("关注实验报告的提交，更关注记录实践能力的提升")
                ])
              ]),
              _c("img", {
                staticClass: "carousel_mobile",
                attrs: {
                  src: require("../../../assets/newIndex/mobile2.png"),
                  alt: ""
                }
              })
            ])
          ]),
          _c("div", { staticClass: "carouselBox" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "left_header" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("\n                Notice"),
                    _c("br"),
                    _vm._v("\n                Board\n              ")
                  ]),
                  _c("img", {
                    staticClass: "header_line",
                    attrs: {
                      src: require("../../../assets/newIndex/line.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("p", { staticClass: "text2" }, [
                  _vm._v("小小公告栏，安全教育、竞赛宣传、主题教育一个都不能少")
                ]),
                _c("p", { staticClass: "text3" }, [
                  _vm._v("通过小程序发布内部宣传通告，传播触达度perfect")
                ])
              ]),
              _c("img", {
                staticClass: "carousel_mobile",
                attrs: {
                  src: require("../../../assets/newIndex/mobile1.png"),
                  alt: ""
                }
              })
            ])
          ])
        ])
      ],
      1
    ),
    _vm._m(1),
    _c("div", { staticClass: "content2" }, [
      _c("ul", [
        _c(
          "li",
          {
            class: _vm.active1 == 1 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(1)
              }
            }
          },
          [
            _vm.active1 != 1
              ? _c("div", [_vm._m(2), _vm._m(3)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("分权限角色管理")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            系统区分管理员、教师、学生角色，并对相应角色采用不同的权限赋权，严控管理黑与流程死角\n          "
                    )
                  ])
                ])
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active1 == 2 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(2)
              }
            }
          },
          [
            _vm.active1 != 2
              ? _c("div", [_vm._m(4), _vm._m(5)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("数据可视化管理")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            设备全生命周期的数据可视化管理，从录入设备上架的那一刻起，实验室设备的借用归还情况就实时记录在册\n          "
                    )
                  ])
                ])
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active1 == 3 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(3)
              }
            }
          },
          [
            _vm.active1 != 3
              ? _c("div", [_vm._m(6), _vm._m(7)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("自动化管理")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            实验设备在上架入柜的之后，不需要重复对设备进行上下架与借用登记，实现全流程的一次上架周期内免维护\n          "
                    )
                  ])
                ])
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active1 == 4 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(4)
              }
            }
          },
          [
            _vm.active1 != 4
              ? _c("div", [_vm._m(8), _vm._m(9)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("辅助线上线下融合教学")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            从借用设备到提交实验报告统一到一个平台，能够实现疫情下非聚集的实验教学管理，助力线上线下融合教学\n          "
                    )
                  ])
                ])
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active1 == 5 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(5)
              }
            }
          },
          [
            _vm.active1 != 5
              ? _c("div", [_vm._m(10), _vm._m(11)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("随时随地发起借用")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            用小程序搜索可用实验设备，无需到达柜体所在位置，高效便捷使用\n          "
                    )
                  ])
                ])
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active1 == 6 ? "active" : "",
            on: {
              click: function($event) {
                return _vm.content2Click(6)
              }
            }
          },
          [
            _vm.active1 != 6
              ? _c("div", [_vm._m(12), _vm._m(13)])
              : _c("div", { staticClass: "active_box" }, [
                  _c("div", { staticClass: "active_title" }, [
                    _vm._v("非接触借用")
                  ]),
                  _c("div", { staticClass: "active_line" }),
                  _c("div", { staticClass: "active_describe" }, [
                    _vm._v(
                      "\n            柜体无广告屏，减低了设备故障率；全流程点击触屏接触，降低了疫情风险传播，更节能省电\n          "
                    )
                  ])
                ])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "content3" }, [
      _c("div", { staticClass: "footer_box" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("支持高校实验室常见设备设施入柜通用性强")
      ]),
      _c("div", { staticClass: "describe" }, [_vm._v("能容万物")]),
      _c("ul", [
        _c(
          "li",
          {
            class: _vm.active2 == 1 ? "li_active" : "",
            on: {
              click: function($event) {
                return _vm.content3Click(1)
              }
            }
          },
          [
            _c("img", {
              staticClass: "img_logo",
              attrs: {
                src: require("../../../assets/newIndex/content3_1.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "li_title" }, [_vm._v("电子元器件")]),
            _c(
              "div",
              {
                class: _vm.active2 == 1 ? "li_footer" : "li_active1 li_footer"
              },
              [
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v("开发板、传感器模块 、电池电机、辅助线材、基础材料等")
                ])
              ]
            )
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active2 == 2 ? "li_active" : "",
            on: {
              click: function($event) {
                return _vm.content3Click(2)
              }
            }
          },
          [
            _c("img", {
              staticClass: "img_logo",
              attrs: {
                src: require("../../../assets/newIndex/content3_2.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "li_title" }, [
              _vm._v("电工电子实验仪器")
            ]),
            _c(
              "div",
              {
                class: _vm.active2 == 2 ? "li_footer" : "li_active1 li_footer"
              },
              [
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v("示波器、万用表、焊台、教学实验箱，开发套件等")
                ])
              ]
            )
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active2 == 3 ? "li_active" : "",
            on: {
              click: function($event) {
                return _vm.content3Click(3)
              }
            }
          },
          [
            _c("img", {
              staticClass: "img_logo",
              attrs: {
                src: require("../../../assets/newIndex/content3_3.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "li_title" }, [_vm._v("机械装备")]),
            _c(
              "div",
              {
                class: _vm.active2 == 3 ? "li_footer" : "li_active1 li_footer"
              },
              [
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v("五金配件、钳子、 量具、刀具、3D打印设备等")
                ])
              ]
            )
          ]
        ),
        _c(
          "li",
          {
            class: _vm.active2 == 4 ? "li_active" : "",
            on: {
              click: function($event) {
                return _vm.content3Click(4)
              }
            }
          },
          [
            _c("img", {
              staticClass: "img_logo",
              attrs: {
                src: require("../../../assets/newIndex/content3_4.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "li_title" }, [_vm._v("通用办公设备")]),
            _c(
              "div",
              {
                class: _vm.active2 == 4 ? "li_footer" : "li_active1 li_footer"
              },
              [
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v("电脑、硬盘、DV摄像机、U盘、教学平板等")
                ])
              ]
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "content4" }, [
      _c("ul", { staticClass: "content4_header" }, [
        _c("li", [
          _c(
            "div",
            {
              staticClass: "txt active",
              on: {
                click: function($event) {
                  return _vm.content4Click(1)
                }
              }
            },
            [_vm._v("场景")]
          ),
          _vm.active3 == 1 ? _c("div", { staticClass: "line" }) : _vm._e()
        ]),
        _c("li", [
          _c(
            "div",
            {
              staticClass: "txt",
              on: {
                click: function($event) {
                  return _vm.content4Click(2)
                }
              }
            },
            [_vm._v("角色")]
          ),
          _vm.active3 == 2 ? _c("div", { staticClass: "line" }) : _vm._e()
        ]),
        _c("li", [
          _c(
            "div",
            {
              staticClass: "txt",
              on: {
                click: function($event) {
                  return _vm.content4Click(3)
                }
              }
            },
            [_vm._v("设备")]
          ),
          _vm.active3 == 3 ? _c("div", { staticClass: "line" }) : _vm._e()
        ])
      ]),
      _vm.active3 == 1
        ? _c("ul", { staticClass: "content4_content" }, [
            _c(
              "li",
              {
                class: _vm.active3Child == 1 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(1)
                  }
                }
              },
              [
                _vm.active3Child != 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_1.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_1_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("专业实验室")]),
                _vm._m(14)
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 2 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(2)
                  }
                }
              },
              [
                _vm.active3Child != 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_2.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_2_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [
                  _vm._v("电工电子中心")
                ]),
                _vm._m(15)
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 3 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(3)
                  }
                }
              },
              [
                _vm.active3Child != 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_3.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_1_3_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [
                  _vm._v("工程训练中心")
                ]),
                _vm._m(16)
              ]
            )
          ])
        : _vm._e(),
      _vm.active3 == 2
        ? _c("ul", { staticClass: "content4_content" }, [
            _c(
              "li",
              {
                class: _vm.active3Child == 1 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(1)
                  }
                }
              },
              [
                _vm.active3Child != 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_1.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_1_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("学生")]),
                _vm._m(17)
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 2 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(2)
                  }
                }
              },
              [
                _vm.active3Child != 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_2.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_2_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [
                  _vm._v("实验室管理员")
                ]),
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v("降低管理劳动强度，周期内免予设备维护")
                ])
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 3 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(3)
                  }
                }
              },
              [
                _vm.active3Child != 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_3.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_3_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("中心主任")]),
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v(
                    "完美记录了实验设备的使用记录，提升了实验室资产的设备使用绩效"
                  )
                ])
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 4 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(4)
                  }
                }
              },
              [
                _vm.active3Child != 4
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_4.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 4
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_2_4_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("实验设备处")]),
                _c("div", { staticClass: "li_describe" }, [
                  _vm._v(
                    "为设备设施的紧急采购和延缓采购提供了依据，提升了资金的有效利用率"
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.active3 == 3
        ? _c("ul", { staticClass: "content4_content" }, [
            _c(
              "li",
              {
                class: _vm.active3Child == 1 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(1)
                  }
                }
              },
              [
                _vm.active3Child != 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_1.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 1
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_1_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [
                  _vm._v("基础电工电子类")
                ])
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 2 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(2)
                  }
                }
              },
              [
                _vm.active3Child != 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_2.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 2
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_2_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("元器件")])
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 3 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(3)
                  }
                }
              },
              [
                _vm.active3Child != 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_3.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 3
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_3_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("机械装备")])
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active3Child == 4 ? "content4_content_active" : "",
                on: {
                  click: function($event) {
                    return _vm.content4ChildClick(4)
                  }
                }
              },
              [
                _vm.active3Child != 4
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_4.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.active3Child == 4
                  ? _c("img", {
                      staticClass: "img_logo",
                      attrs: {
                        src: require("../../../assets/newIndex/content4_3_4_active.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "li_title" }, [_vm._v("通用办公设备")])
              ]
            )
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "content5" }, [
      _c("div", { staticClass: "content5_box" }, [
        _c("div", { staticClass: "tit" }, [_vm._v("开始使用毅力实验通")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v("一起完成目标，让进步发生。")
        ]),
        _c("div", { staticClass: "footer_action" }, [
          _c("div", { staticClass: "f_left", on: { click: _vm.login } }, [
            _vm._v("立即登录")
          ]),
          _c("div", { staticClass: "f_right", on: { click: _vm.yuyue } }, [
            _c("img", {
              staticClass: "img",
              attrs: {
                src: require("../../../assets/newIndex/action.png"),
                alt: "logo"
              }
            }),
            _vm._v("预约演示\n        ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "explain" }, [
      _c("ul", [
        _c("li", [
          _c("div", [
            _c("img", {
              staticClass: "explain-logo",
              attrs: {
                src: require("../../../assets/newIndex/explain-user.png"),
                alt: "logo"
              }
            }),
            _c("p", { staticClass: "title" }, [_vm._v("三类人群")]),
            _c("p", { staticClass: "title1" }, [_vm._v("管理员、教师、学生")])
          ])
        ]),
        _c("li", [
          _c("div", [
            _c("img", {
              staticClass: "explain-logo",
              attrs: {
                src: require("../../../assets/newIndex/explain-phone.png"),
                alt: "logo"
              }
            }),
            _c("p", { staticClass: "title" }, [_vm._v("小程序")]),
            _c("p", { staticClass: "title1" }, [_vm._v("便携服务贴身服务")])
          ])
        ]),
        _c("li", [
          _c("div", [
            _c("img", {
              staticClass: "explain-logo",
              attrs: {
                src: require("../../../assets/newIndex/explain-pc.png"),
                alt: "logo"
              }
            }),
            _c("p", { staticClass: "title" }, [_vm._v("管理后台")]),
            _c("p", { staticClass: "title1" }, [_vm._v("管好系统数据")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content1" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "left_box" }, [
          _c("div", { staticClass: "title1" }, [
            _vm._v("物联网与AI技术的助力 实验实践设备管理")
          ]),
          _c("div", { staticClass: "describe" }, [
            _vm._v(
              "智能设备管理柜利用物联网智能锁，配合系统软件实现后台管理端+多个小程序端"
            )
          ]),
          _c("ul", { staticClass: "characteristic" }, [
            _c("li", [
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../../assets/newIndex/characteristic.png"),
                  alt: ""
                }
              }),
              _vm._v("可用性")
            ]),
            _c("li", [
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../../assets/newIndex/characteristic.png"),
                  alt: ""
                }
              }),
              _vm._v("安全性")
            ]),
            _c("li", [
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../../assets/newIndex/characteristic.png"),
                  alt: ""
                }
              }),
              _vm._v("快速搭建")
            ]),
            _c("li", [
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../../assets/newIndex/characteristic.png"),
                  alt: ""
                }
              }),
              _vm._v("灵活扩展")
            ])
          ])
        ]),
        _c("div", { staticClass: "right_img" }, [
          _c("img", {
            staticClass: "right1",
            attrs: {
              src: require("../../../assets/newIndex/content1_right.png"),
              alt: ""
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("分权限角色"),
      _c("br"),
      _vm._v("管理")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_2.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("数据可视化"),
      _c("br"),
      _vm._v("管理")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_2.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("自动化"),
      _c("br"),
      _vm._v("管理")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_3.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("辅助线上"),
      _c("br"),
      _vm._v("线下融合"),
      _c("br"),
      _vm._v("教学")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_4.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("随时随地"),
      _c("br"),
      _vm._v("发起借用")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_5.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "describe" }, [
      _vm._v("非接触"),
      _c("br"),
      _vm._v("借用")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right_img" }, [
      _c("img", {
        staticClass: "bottom_img",
        attrs: {
          src: require("../../../assets/newIndex/content2_5.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li_describe" }, [
      _vm._v("实验设备智能"),
      _c("br"),
      _vm._v("管理管控")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li_describe" }, [
      _vm._v("常见电工电子仪器的"),
      _c("br"),
      _vm._v("开放共享")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li_describe" }, [
      _vm._v("五金易耗低值工具的"),
      _c("br"),
      _vm._v("使用记录")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li_describe" }, [
      _vm._v("更方便的借用"),
      _c("br"),
      _vm._v("实验实践设备")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }