<template>
  <a-spin :spinning="confirmLoading">
   <div class="staticBox">
    <div class="box">
      <a-progress type="circle" :percent="static.annotated" :width="80">
      <template #format="percent">
        <span style="color: red">{{ percent }}</span>
      </template>
    </a-progress><br />已批注
    </div>
    <div class="box"><a-progress type="circle" :percent="static.completionRate" :width="80"/><br />完成率</div>
    <div class="box"><a-progress type="circle" :percent="static.excellenceRate" :width="80"/><br />优秀率</div>
    <div class="box"><a-progress type="circle" :percent="static.submitted" :width="80">
      <template #format="percent">
        <span style="color: red">{{ percent }}</span>
      </template>
    </a-progress><br />已提交数</div>
    </div>
    <a-tabs v-model="activeKey">
      <a-tab-pane  :key="item.id" v-for="(item, index) in classData"  @change="getReport(item.id)" :tab="item.name"></a-tab-pane>
    </a-tabs>
    <!-- size="middle"
        :scroll="{ x: true }" -->
    <a-table
    size="middle"
        :scroll="{ x: true }" 
        ref="table"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="reportData"
        :pagination="ipagination"
        class="j-table-force-nowrap"
      >
        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="status" slot-scope="text, record">
          <div v-if="record.status==-1">待上传</div>
          <div v-if="record.status==1">已上传</div>
          <div v-if="record.status==2">已审批</div>
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="openReport(record)">详情</a>
          <a-divider type="vertical" />
          <a @click="openAnnotations(record)"  v-if="record.status!=2">批注</a>
        </span>
      </a-table>
      <a-modal
      v-model="addDialog"
        :title="title"
        :width="width"
        placement="right"
        :closable="false"
        @close="close"
        @ok="handleOk"
        :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
        destroyOnClose
        :visible="visible">
        <task-form2 ref="realForm2" :reportId="reportId" :record="record" @ok="submitCallback" :disabled="disableSubmit" normal></task-form2>
      </a-modal>
      <a-modal
      v-model="addDialog1"
        title="详情"
        :width="width1"
        :height="height1"
        placement="right"
        :closable="false"
        @close="close"
        :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
        destroyOnClose
        :visible="visible">
        <div class="viewBox">
          <div class="title">{{reportInfo.title}}</div>
          <div class="remark">{{reportInfo.remark}}</div>
        </div>

          <div class="flexBox">上传的报告:
            <!-- <embed type="application/docx" src="reportInfo.files[0] from qiniu"></embed>  -->
            <div v-for="(item,index) in reportInfo.files" :key="index" class="flex">
                报告{{index+1}}
                <a style="margin-left:5px;" href="javascript:;" @click="preview(item)">预览</a>
                <a style="margin-left:5px;" href="javascript:;" @click="download(item)">下载</a>
            </div>
           
            </div>
        <template #footer>
        <a-button key="back" @click="addDialog1=false">关闭</a-button>
      </template>
      </a-modal>
  </a-spin>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JUpload from '@/components/jeecg/JUpload'
  import TaskForm2 from './TaskForm2.vue'
  import store from '@/store'
  export default {
    name: 'TaskForm1',
      mixins: [ mixinDevice],
    components: {
    JUpload,
    TaskForm2
  },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        reportInfo:{
            files:[],
            title:'',
            remark:'',
        },
        static:{},
        record:{},
        /* 分页参数 */
        // ipagination: {
        //   current: 1,
        //   pageSize: 5,
        //   pageSizeOptions: ['5', '10', '20', '30'],
        //   showTotal: (total, range) => {
        //     return range[0] + '-' + range[1] + ' 共' + total + '条'
        //   },
        //   showQuickJumper: true,
        //   showSizeChanger: true,
        //   total: 0
        // },
        reportId:'',
        addDialog:false,
        addDialog1:false,
        title:'批注',
        width:600,
        width1:900,
        height1:900,
        visible: false,
        disableSubmit: false,
        reportData:{},
        activeKey:'1',
        store,
        categoryList:[],
        classData:[],
        model:{
          resources:[],
          formworkUrl:''
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        columns: [
        /* {
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          }, */
        {
          title: '学生编号',
          align: 'center',
          width: 150,
          dataIndex: 'studentCode'
          
        },
        {
          title: '学生姓名',
          align: 'center',
          width: 150,
          dataIndex: 'studentName'
        },
        {
          title: '任务名称',
          align: 'center',
          dataIndex: 'title',
          width: 150,
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 150,
        },
        
        {
          title: '评分',
          align: 'center',
          dataIndex: 'source',
          width: 150,
        },
        {
          title: '提交报告时间',
          align: 'center',
          dataIndex: 'createTime',
          width: 150,
        },
        {
          title: '备注',
          align: 'center',
          width: 150,
          ellipsis: true,
          dataIndex: 'remark',
        },
        /* {
            title:'任务描述',
            align:"center",
            dataIndex: 'description'
          }, */
        // {
        //   title: '开始时间',
        //   align: 'center',
        //   dataIndex: 'startTime',
        // },
        // {
        //   title: '结束时间',
        //   align: 'center',
        //   dataIndex: 'endTime',
        // },
        // {
        //   title: '发布状态',
        //   align: 'center',
        //   dataIndex: 'isPublish_dictText',
        // },
        // {
        //   title: '发布时间',
        //   align: 'center',
        //   dataIndex: 'publishTime',
        // },
        // {
        //   title: '状态',
        //   align: 'center',
        //   dataIndex: 'isEnable_dictText',
        // },
        // {
        //   title: '创建时间',
        //   align: 'center',
        //   dataIndex: 'createTime',
        //   customRender: function (text) {
        //     return !text ? '' : text.length > 10 ? text.substr(0, 10) : text
        //   },
        // },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 147,
          scopedSlots: { customRender: 'action' },
        },
      ],
        confirmLoading: false,
        validatorRules: {
           title: [
              { required: true, message: '请输入任务标题!'},
           ],
           classes: [
              { required: true, message: '请选择班级!'},
           ],
           categoryId: [
              { required: true, message: '请选择设备分类!'},
           ],
           asstes: [
              { required: true, message: '请选择设备!'},
           ],
           isEnable: [
              { required: true, message: '请输入状态!'},
           ],
        },
        url: {
          //list:"system/experimentalReport/list/new",
          add: "/ylpp/task/add",
          edit: "/ylpp/task/edit",
          queryById: "/ylpp/task/queryById",
          classPulldownUrl: 'ylpp/teacher/classes',
          categoryPulldownUrl: 'ylpp/assets/pulldown',
          reportUrlNew: "system/experimentalReport/list/new",
          reportUrl: "system/experimentalReport/list",
          statistics: "system/experimentalReport/statistics",
          queryById: "system/experimentalReport/queryById",
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },

    watch: {
        activeKey() {
            this.getReport();
            this.getStatistics();
        }
    },
    created () {
      this.renderCategory();
      this.getClass();
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
        //this.viewNews(record)
        
      },
      viewNews(record){
        getAction(this.url.newsView,{id:record.id}).then((res)=>{
             record.classes = res.result.classes;
             record.asstes = res.result.asstes;
             record.resources = res.result.resources;
             this.model = Object.assign({}, record);
          })
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            if(typeof this.model.resources=='string'&&this.model.resources){
              this.model.resources=this.model.resources.split(',')
            }
            if(!this.model.resources){
              this.model.resources=[];
            }
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },getClass(name) {
       // var userId = store.getters.userInfo.id;
      httpAction(`${this.url.classPulldownUrl}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.classData=res.result;
            this.activeKey=this.classData[0]['id']
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
      },getReport(id) {
       // var userId = store.getters.userInfo.id;
       let params={
          "classId": this.activeKey,
          "pageNo": 1,
          "pageSize": 10,
          "studentName": "",
          "taskId": this.model.id
       }
      httpAction(`${this.url.reportUrlNew}`, params, 'post')
        .then((res) => {
          if (res.success) {
            if(res.result){
              this.reportData=res.result.records;
            }
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
      },getStatistics(id) {
       // var userId = store.getters.userInfo.id;
       
      httpAction(`${this.url.statistics}?classId=${this.activeKey}&taskId=${this.model.id}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            if(res.result){
              res.result.completionRate=parseInt(res.result.completionRate*100)
              res.result.excellenceRate=parseInt(res.result.excellenceRate*100)
              this.static=res.result;
            }
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },renderCategory() {
      let that = this
      httpAction(`${this.url.categoryPulldownUrl}?name=`, {}, 'get')
        .then((res) => {
          if (res.success) {
            res.result.forEach((item)=>{
              item.value=item.id;
              item.label=item.name;
              //item.text=item.name;
            })
            this.categoryList=res.result;
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },
    handleEdit: function (record) {
      this.reportId=record.id;
      this.$refs.modalForm2.edit(record);
    },
    handleSearch(value) {
      this.getClass(value)
    },modalFormOk(){

    },close(){
      this.addDialog=false;
    },openAnnotations(record){
      this.record=record;
      this.reportId=record.id;
      this.visible=true;
      this.addDialog=true;
    },handleOk() {
        this.$refs.realForm2.submitForm();
    },submitCallback(){
      this.addDialog=false;
      this.getReport();
    },openReport(record){
      this.record=record;
      this.reportId=record.id;
      this.visible=true;
        this.$nextTick(()=>{
          //this.$refs.realForm2.edit(record);
        })
      let that = this
      httpAction(`${this.url.queryById}?id=${this.reportId}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.reportInfo=res.result;
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
        //this.url.queryById
      this.addDialog1=true;
    },preview(url){
      window.open(url + '?doc-convert/preview')
    },download(url){
      window.location.href=url;
    }
  
  }
  }
</script>
<style>
.ant-tabs-nav .ant-tabs-tab-active {
    color: #1890FF !important;
    text-shadow: 0 0 0.25px currentColor;
}
.box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.staticBox{
  display: flex;
  justify-content: space-around;
}
.title{
  font-size: 26px;
    /* font-weight: 700; */
    padding: 10px 0px;
    /* margin-bottom: 100px; */
    text-align: center;
    border-bottom: 1px solid #ccc;
}
.remark{
  font-size:20px;
  text-indent:20px;
}
.remark {
    font-size: 17px;
    text-indent: 20px;
    padding: 20px;
}
.flexBox{
  display:flex;
  margin-top: 20px;
  font-size: 16px;
}
.ml20{
  margin-left:20px;
}
.viewBox{
  min-height: 400px;
  border: 2px solid #ccc;
  border-radius: 5px;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>