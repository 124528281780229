var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result" }, [
    _c(
      "div",
      [
        _c("a-icon", {
          class: [_vm.isSuccess ? "success" : "error", "icon"],
          attrs: { type: _vm.isSuccess ? "check-circle" : "close-circle" }
        })
      ],
      1
    ),
    _vm.title
      ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _vm.description
      ? _c("div", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.description))
        ])
      : _vm._e(),
    _vm.content
      ? _c("div", { staticClass: "content" }, [_vm._t("default")], 2)
      : _vm._e(),
    _c("div", { staticClass: "action" }, [_vm._t("action")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }