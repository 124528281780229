var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-col",
        { staticClass: "clName", attrs: { span: 4 } },
        [
          _c("a-tree", {
            staticStyle: { height: "500px", "overflow-y": "auto" },
            attrs: {
              treeData: _vm.treeData,
              defaultExpandAll: _vm.defaultExpandAll
            },
            on: { select: this.onSelect }
          })
        ],
        1
      ),
      _c("a-col", { attrs: { span: 2 } }),
      _c(
        "a-col",
        { attrs: { span: 18 } },
        [
          _c(
            "a-spin",
            { attrs: { tip: "Loading...", spinning: _vm.spinning } },
            _vm._l(_vm.dataSource, function(file, key) {
              return _c(
                "div",
                { key: key },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c(
                          "p",
                          [
                            _c(
                              "a-divider",
                              { attrs: { orientation: "left" } },
                              [_vm._v(_vm._s(file.fileName))]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          file.filePdfPath
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      width: "104px",
                                      height: "104px",
                                      "margin-right": "10px",
                                      margin: "0 8px 8px 0"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          position: "relative",
                                          padding: "8px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.pdfPreview(file.title)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            src: require("@/assets/pdf4.jpg")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            : [
                                _vm._v(
                                  '\n              (暂无材料，点击"选择文件"或"扫描上传"上传文件)\n            '
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c("pdf-preview-modal", { ref: "pdfmodal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }